import axios from 'axios';

let instance = axios.create({
  baseURL: window._env_.API_URL,
  responseType: 'json',
  headers: {
    'x-access-apikey': window._env_.API_APIKEY,
    'x-access-token': localStorage.getItem('access-jwt')
  }
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!error.response) {
      console.log('Please check your internet connection.');
    }

    return Promise.reject(error);
  }
);

function refreshToken(token) {
  instance.defaults.headers['x-access-token'] = token;
}

export { refreshToken };
export default instance;
