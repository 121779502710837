import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, IconButton, TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import Api from '../../Api';

const schema = {
  email: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    email: { message: '^Invalido' },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 8,
      maximum: 128,
      message: '^Debe contener al menos 8 caracteres'
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    equality: {
      attribute: 'password',
      message: '^No coincide con la contraseña ingresada'
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/draws/password_reset.svg)',
    backgroundSize: '55% 55%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  resetPasswordButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ResetPassword = props => {
  const { history } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleResetPassword = async event => {
    event.preventDefault();
    Api.post('/users/password/action/reset', {
      email: formState.values.email,
      password: formState.values.password,
      confirmPath: '/confirm-password'
    })
      .then(function(response) {
        enqueueSnackbar('¡IMPORTANTE! Te enviamos un email para que confirmes el cambio de contraseña', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const handleBack = () => {
    history.goBack();
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={6}>
          <div className={classes.quote}>
            <div className={classes.quoteInner} />
          </div>
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleResetPassword}>
                <Typography align="center" className={classes.title} variant="h2">
                  Recuperacion de Contraseña
                </Typography>
                <Typography align="center" color="textSecondary" gutterBottom>
                  Te vamos a enviar un email para activar tu nueva contraseña
                </Typography>

                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password[0] : null}
                  label="Contraseña"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('confirmPassword')}
                  fullWidth
                  helperText={hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null}
                  label="Confirmacion de Contraseña"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.confirmPassword || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.resetPasswordButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Recuperar
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object
};

export default withRouter(ResetPassword);
