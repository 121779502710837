import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

import ScheduleDetailModal from './ScheduleDetailModal';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import GenericToolbar from '../../../components/GenericToolbar/GenericToolbar';
import NewScheduleDetailModal from './NewScheduleDetailModal';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const ScheduleModal = ({ handleClose, logedUser, loadSelectedSchedule, visibility, schedule, statuses, loadSchedules, sectors, matrices, items }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortDirection: 'desc'
      }
    },
    {
      name: 'year',
      label: 'Año',
      options: {
        sort: true
      }
    },
    {
      name: 'month_name',
      label: 'Mes',
      options: {
        sort: true
      }
    },
    {
      name: 'matrix.name',
      label: 'Matriz',
      options: {
        sort: true
      }
    },
    {
      name: 'item.name',
      label: 'Servicio / Trabajo',
      options: {
        sort: true
      }
    },
    {
      name: 'objective',
      label: 'Total',
      options: {
        sort: true
      }
    },
    {
      name: 'progress',
      label: 'Parcial',
      options: {
        sort: true
      }
    },
    {
      name: 'comment',
      label: 'Comentarios',
      options: {
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    onRowClick: row => {
      handleClickOpen(row);
    },
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [visibilityScheduleDetailModal, setVisibilityScheduleDetailModal] = useState(false);
  const [selectedScheduleDetail, setSelectedScheduleDetail] = useState({});

  const handleClickOpen = row => {
    schedule.schedules_details.forEach(schedule_detail => {
      if (schedule_detail.id === row[0]) {
        setSelectedScheduleDetail(schedule_detail);
      }
    });
    setVisibilityScheduleDetailModal(true);
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [updatingSchedule, setUpdatingSchedule] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState();
  const [deletingScheduleID, setDeletingScheduleID] = useState(0);
  const [deletingScheduleDetails, setDeletingSchedulesDetails] = useState(false);
  const [newStatusId, setNewStatusId] = useState({});
  const [modified, setModified] = useState(false);
  const [confirmDeleteDialogVisibility, setConfirmDeleteDialogVisibility] = useState(false);
  const [confirmDismissDialogVisibility, setConfirmDismissDialogVisibility] = useState(false);

  useEffect(() => {
    setNewStatusId(schedule.schedule_status_id);
    setScheduleDetails(schedule.schedules_details);
  }, [schedule]);

  useEffect(() => {
    if (deletingScheduleID !== 0 && deletingScheduleDetails < 1) {
      deleteSchedule(deletingScheduleID);
    }
    if (close && !updatingSchedule) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, deletingScheduleDetails]);

  useEffect(() => {
    if (close && !updatingSchedule) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, updatingSchedule]);

  useEffect(() => {
    if (newStatusId === schedule.schedule_status_id) {
      setModified(false);
    } else {
      setModified(true);
    }
  }, [newStatusId]);

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisibility(false);
  };

  const handleDelete = () => {
    setConfirmDeleteDialogVisibility(true);
  };

  const handleOnConfirmDelete = () => {
    setConfirmDeleteDialogVisibility(false);
    handleDeleteSchedule();
  };

  const handleCloseConfirmDismissDialog = () => {
    setConfirmDismissDialogVisibility(false);
  };

  const handleCloseDismiss = () => {
    if (modified) {
      setConfirmDismissDialogVisibility(true);
    } else {
      handleClose();
    }
  };

  const handleOnConfirmDismiss = () => {
    setConfirmDismissDialogVisibility(false);
    handleClose();
  };

  const handleCloseScheduleDetailModal = () => {
    setVisibilityScheduleDetailModal(false);
  };

  const handleChangeStatus = selectedStatusId => {
    setNewStatusId(selectedStatusId);
  };

  const handleSaveSchedule = async event => {
    event.preventDefault();
    setLoading(true);

    if (newStatusId !== schedule.schedule_status_id) {
      setUpdatingSchedule(true);
      updateScheduleStatus(schedule.id, newStatusId);
    }

    setAddingScheduleDetail(newScheduleDetails.length);
    newScheduleDetails.forEach(new_schedule_detail => {
      createScheduleDetail(schedule.id, new_schedule_detail);
    });
    if (newScheduleDetails.length > 0){
      enqueueSnackbar('Cronograma actualizado exitosamente!', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        variant: 'success'
      });
    }
    setNewScheduleDetails([])
    setModified(false)
    setClose(true);
  };

  const handleDeleteSchedule = () => {
    setLoading(true);

    if (schedule.schedules_details.length !== 0) {
      setDeletingSchedulesDetails(schedule.schedules_details.length);
      schedule.schedules_details.forEach(async schedule_detail => {
        await deleteScheduleDetail(schedule.id, schedule_detail, true);
      });
    }
    setDeletingScheduleID(schedule.id);
    setClose(true);
  };

  const deleteSchedule = async scheduleId => {
    Api.delete('/schedules/' + scheduleId)
      .then(function() {
        loadSchedules();
        enqueueSnackbar('Cronograma eliminado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function() {
        return false;
      });
  };

  const deleteScheduleDetail = async (scheduleId, scheduleDetail, inScheduleDelete = false) => {
    Api.delete('/schedules_details/' + scheduleDetail.id)
      .then(function() {
        setDeletingSchedulesDetails(deletingScheduleDetails - 1);
        if (!inScheduleDelete) {
          enqueueSnackbar('Detalle de Cronograma removido exitosamente!', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success'
          });
        }
      })
      .catch(function() {
        setDeletingSchedulesDetails(deletingScheduleDetails - 1);
        return false;
      });
  };

  const updateScheduleStatus = async (scheduleId, statusId) => {
    var data = {
      schedule_status_id: statusId
    };

    Api.put('/schedules/' + scheduleId, data)
      .then(function(response) {
        setUpdatingSchedule(false);
        enqueueSnackbar('Cronograma actualizado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        setUpdatingSchedule(false);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const [addingScheduleDetail, setAddingScheduleDetail] = useState(0);
  const [newScheduleDetails, setNewScheduleDetails] = useState([]);

  useEffect(() => {
    if (close && addingScheduleDetail < 1) {
      loadSelectedSchedule();
      setLoading(false);
      handleClose(true);
    }
  }, [close, addingScheduleDetail]);

  const addNewDetailModal = schedulesDetails => {
    setModified(true)
    var schedules_details = scheduleDetails;
    var new_schedules_details = newScheduleDetails;
    schedulesDetails.forEach(new_schedule_detail => {
      new_schedule_detail.month_name = new_schedule_detail.month.name
      schedules_details.push(new_schedule_detail);
      new_schedules_details.push(new_schedule_detail);
    });
    setScheduleDetails(schedules_details);
    setNewScheduleDetails(new_schedules_details);
  };

  const createScheduleDetail = async (scheduleId, scheduleDetail) => {
    Api.post('/schedules_details', {
      schedule_id: scheduleId,
      sector_id: scheduleDetail.sector.id,
      matrix_id: scheduleDetail.matrix.id,
      item_id: scheduleDetail.item.id,
      year: parseInt(scheduleDetail.year),
      month: scheduleDetail.month.id,
      objective: parseInt(scheduleDetail.objective),
      progress: parseInt(scheduleDetail.progress),
      comment: scheduleDetail.comment
    })
    .then(function() {
      setAddingScheduleDetail(addingScheduleDetail - 1);
      return true;
    })
    .catch(function() {
      setAddingScheduleDetail(addingScheduleDetail - 1);
      return false;
    });
  };

  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date().setHours(0, 0, 0, 0));

  const [visibilityNewScheduleDetail, setVisibilityNewScheduleDetail] = useState(false);
  const handleClickOpenNewScheduleDetail = () => {
    setVisibilityNewScheduleDetail(true);
  };

  const handleCloseNewScheduleDetail = success => {
    setVisibilityNewScheduleDetail(false);
  };

  if (schedule.client !== undefined) {
    return (
      <div>
        <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Cronograma" maxWidth="lg">
          <form onSubmit={handleSaveSchedule}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    className={styles.marginTop}
                    multiline
                    disabled
                    fullWidth
                    label="Nro. de Cotización"
                    name="quotation_identifier"
                    type="text"
                    value={schedule.quotation_identifier || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={styles.marginTop}
                    name="statuses"
                    disabled={logedUser.u_role_id === 3 ? true : false}
                    options={statuses}
                    getOptionLabel={option => option.name}
                    defaultValue={schedule.status}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangeStatus(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Estado" />}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={styles.marginTop}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Fecha de Inicio"
                    name="start_on_formatted"
                    type="text"
                    value={schedule.start_on_formatted || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Fecha de Fin"
                    name="end_on_formatted"
                    type="text"
                    value={schedule.end_on_formatted || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <div className={styles.marginTop}>
                {logedUser.u_role_id !== 3 ? (
                  <GenericToolbar handleClickOpenNew={handleClickOpenNewScheduleDetail} />
                ): null}
                <MUIDataTable title={'Detalle de Cronograma'} data={scheduleDetails} columns={columns} options={options} />
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {!schedule.plant ? (
                    <Card className={styles.marginTop}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Cliente" name="client" type="text" value={schedule.client.name || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              disabled
                              fullWidth
                              label="Documento"
                              name="document"
                              type="text"
                              value={schedule.client.document || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Teléfono" name="phone" type="text" value={schedule.client.phone || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField disabled fullWidth label="E-mail" name="email" type="text" value={schedule.client.email || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Provincia" name="state" type="text" value={schedule.client.state || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Localidad" name="city" type="text" value={schedule.client.city || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled
                              fullWidth
                              label="Dirección"
                              name="address"
                              type="text"
                              value={schedule.client.address || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card className={styles.marginTop}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Planta" name="client" type="text" value={schedule.plant.name || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              disabled
                              fullWidth
                              label="Contacto"
                              name="document"
                              type="text"
                              value={schedule.plant.contact || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Teléfono" name="phone" type="text" value={schedule.plant.phone || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField disabled fullWidth label="E-mail" name="email" type="text" value={schedule.plant.email || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Provincia" name="state" type="text" value={schedule.plant.state || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Localidad" name="city" type="text" value={schedule.plant.city || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled
                              fullWidth
                              label="Dirección"
                              name="address"
                              type="text"
                              value={schedule.plant.address || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            {logedUser.u_role_id !== 3 ? (
              <DialogActions>
                <div style={{ flex: '1 0 0' }} />
                <Button onClick={handleDelete} style={{ color: 'red' }}>
                  Eliminar
                </Button>
                <Button disabled={!modified} type="submit" color="primary">
                  Guardar
                </Button>
              </DialogActions>
            ) : null}
          </form>
          <ConfirmDialog
            handleClose={handleCloseConfirmDeleteDialog}
            handleOnConfirm={handleOnConfirmDelete}
            visibility={confirmDeleteDialogVisibility}
            title="¿Está seguro que desea eliminar este cronograma?"
            message="La eliminación de este cronograma es permanente"
          />
          <ConfirmDialog
            handleClose={handleCloseConfirmDismissDialog}
            handleOnConfirm={handleOnConfirmDismiss}
            visibility={confirmDismissDialogVisibility}
            title="¿Está seguro que desea salir?"
            message="Hay cambios sin guardar"
          />
        </Modal>
        <ScheduleDetailModal
          handleClose={handleCloseScheduleDetailModal}
          onSuccess={loadSelectedSchedule}
          logedUser={logedUser}
          visibility={visibilityScheduleDetailModal}
          scheduleDetail={selectedScheduleDetail}
          loadSelectedSchedule={loadSelectedSchedule}
        />
        <NewScheduleDetailModal
          handleClose={handleCloseNewScheduleDetail}
          addNewDetailModal={addNewDetailModal}
          visibility={visibilityNewScheduleDetail}
          sectors={sectors}
          matrices={matrices}
          items={items}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
        />
        <Backdrop className={styles.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ScheduleModal;
