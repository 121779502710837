import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import JWTDecoder from 'jwt-decode';

import Api from '../../Api';
import JobModal from './components/JobModal';
import NewJobModal from './components/NewJobModal';
import GenericToolbar from '../../components/GenericToolbar/GenericToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Jobs = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'client.name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'plant.name',
      label: 'Planta',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'organism',
      label: 'Organismo',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'proceedings',
      label: 'Expediente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'notification',
      label: 'Notificación',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status.name',
      label: 'Estado',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'notice_on_formatted',
      label: 'Fecha de Notificación',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'expire_on_formatted',
      label: 'Fecha de Vencimiento',
      options: {
        filter: false,
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    onRowClick: row => {
      handleClickOpen(row);
    },
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [visibility, setVisibility] = useState(false);
  const [visibilityNew, setVisibilityNew] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [selectedJobFiles, setSelectedJobFiles] = useState({});

  const handleClickOpen = row => {
    jobs.forEach(job => {
      if (job.id === row[0]) {
        loadUsers(job.client_id);
        setSelectedJob(job);
        setSelectedJobFiles(job.files);
      }
    });
    setVisibility(true);
  };

  const handleClickOpenNew = () => {
    setVisibilityNew(true);
  };

  const handleCloseNew = success => {
    if (success) {
      loadJobs();
    }
    setVisibilityNew(false);
  };

  const handleClose = success => {
    if (success) {
      loadJobs();
    }
    setVisibility(false);
  };

  const [jobs, setJobs] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [jobFilesToDelete, setJobFilesToDelete] = useState([]);
  const handleFileToDelete = file => {
    const jobFilesFiltered = selectedJobFiles.filter(function(el) {
      return el !== file;
    });
    setSelectedJobFiles(jobFilesFiltered);
    jobFilesToDelete.push(file);
    setJobFilesToDelete(jobFilesToDelete);
    console.log(jobFilesToDelete);
  };

  const loadJobs = () => {
    Api.get('/jobs?archived=false')
      .then(function(response) {
        response.data.data.forEach(r => {
          r.histories.forEach(h => {
            h.created_on_formatted = moment(h.created_on).format('DD/MM/YYYY HH:mm');
            h.user.fullname = h.user.firstname + ' ' + h.user.lastname;
          });
        });
        response.data.data.forEach(r => {
          r.full_users = [];
          r.users.forEach(u => {
            r.full_users.push(u.user);
          });
        });
        response.data.data.forEach(r => {
          r.expire_on_formatted = moment(r.expire_on).format('DD/MM/YYYY');
        });
        response.data.data.forEach(r => {
          r.notice_on_formatted = moment(r.notice_on).format('DD/MM/YYYY');
        });
        response.data.data.forEach(r => {
          r.warn_on_formatted = moment(r.warn_on).format('DD/MM/YYYY');
        });

        setJobs(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setJobs([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const loadUsers = clientId => {
    var url = '/users';
    if (typeof clientId != 'undefined') {
      url = '/users?client_id=' + clientId;
    }
    Api.get(url)
      .then(function(response) {
        response.data.data.forEach(r => {
          r.fullname = r.firstname + ' ' + r.lastname;
          if (r.client) {
            r.fullname = r.fullname + ' | ' + r.client.name;
            if (r.plant) {
              r.fullname = r.fullname + ' - ' + r.plant.name;
            }
          }
        });
        setUsers(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setUsers([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  useEffect(() => {
    loadJobs();
    loadUsers();
    Api.get('/clients')
      .then(function(response) {
        setClients(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });

    Api.get('/jobs_statuses')
      .then(function(response) {
        setStatuses(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setStatuses([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  }, []);

  return (
    <div className={classes.root}>
      {jwtUser.u_role_id !== 3 ? <GenericToolbar handleClickOpenNew={handleClickOpenNew} /> : null}
      <div className={classes.content}>
        <MUIDataTable title={'Lista de Vencimientos'} data={jobs} columns={columns} options={options} />
      </div>
      <JobModal
        handleClose={handleClose}
        handleFileToDelete={handleFileToDelete}
        logedUser={jwtUser}
        visibility={visibility}
        job={selectedJob}
        jobFiles={selectedJobFiles}
        users={users}
        statuses={statuses}
      />
      <NewJobModal handleClose={handleCloseNew} loadUsers={loadUsers} visibility={visibilityNew} users={users} clients={clients} statuses={statuses} />
    </div>
  );
};

export default Jobs;
