import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

const schema = {
  name: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 2,
      maximum: 64,
      message: '^Debe contener al menos 2 caracteres'
    }
  },
  document: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 10,
      maximum: 64,
      message: '^Debe contener al menos 10 números'
    }
  },
  email: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    email: { message: '^Invalido' },
    length: {
      maximum: 64,
      message: '^No debe contener mas de 64 caracteres'
    }
  },
  phone: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 8,
      maximum: 128,
      message: '^Debe contener al menos 8 caracteres'
    }
  },
  address: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 4,
      maximum: 128,
      message: '^Debe contener al menos 4 caracteres'
    }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const NewClientModal = ({ handleClose, loadCities, visibility, states, cities }) => {
  const { enqueueSnackbar } = useSnackbar();
  let createBtnRef = useRef();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const [selectedState, setSelectedState] = useState();
  const handleChangeState = (stateId, state) => {
    setSelectedState(state);
    loadCities(stateId);
  };

  const [selectedCity, setSelectedCity] = useState();
  const handleChangeCity = city => {
    setSelectedCity(city);
  };

  const handleCreateClient = async event => {
    event.preventDefault();
    if(createBtnRef.current){
      createBtnRef.current.setAttribute('disabled', 'disabled');
    }
    Api.post('/clients', {
      name: formState.values.name,
      document: formState.values.document,
      email: formState.values.email,
      phone: formState.values.phone,
      address: formState.values.address,
      state: selectedState,
      city: selectedCity
    })
      .then(function(response) {
        if(createBtnRef.current){
          createBtnRef.current.removeAttribute("disabled");
        }
        formState.values.name = '';
        formState.values.document = '';
        formState.values.email = '';
        formState.values.phone = '';
        formState.values.address = '';
        handleClose(true);
        enqueueSnackbar('Cliente creado con éxito!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        if(createBtnRef.current){
          createBtnRef.current.removeAttribute("disabled");
        }
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          if (error.response.data.code == 1505) {
            message = error.response.data.message
          } else {
            message = 'Ocurrio un error, intenta nuevamente.';
          }
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div>
      <Modal handleClose={handleClose} visibility={visibility} title="Nuevo Cliente">
        <form onSubmit={handleCreateClient}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  error={hasError('name')}
                  fullWidth
                  helperText={hasError('name') ? formState.errors.name[0] : null}
                  label="Razón Social"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={hasError('document')}
                  fullWidth
                  helperText={hasError('document') ? formState.errors.document[0] : null}
                  label="CUIT"
                  name="document"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.document || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  label="E-Mail"
                  name="email"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.email || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={hasError('phone')}
                  fullWidth
                  helperText={hasError('phone') ? formState.errors.phone[0] : null}
                  label="Número de teléfono"
                  name="phone"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.phone || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  name="states"
                  options={states}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeState(value.id, value.name);
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Provincia" />}
                  error={hasError('states')}
                  helperText={hasError('states') ? formState.errors.states[0] : null}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  name="cities"
                  options={cities}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  noOptionsText={'No hay opciones para la provincia seleccionada'}
                  onChange={(option, value) => {
                    handleChangeCity(value.name);
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Localidad" />}
                  error={hasError('cities')}
                  helperText={hasError('cities') ? formState.errors.cities[0] : null}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  error={hasError('address')}
                  fullWidth
                  helperText={hasError('address') ? formState.errors.address[0] : null}
                  label="Dirección"
                  name="address"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.address || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button ref={createBtnRef}  disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </div>
  );
};

export default NewClientModal;
