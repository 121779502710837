import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import momentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

moment.locale('es');

const schema = {
  organism: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 3,
      maximum: 64,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  description: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 3,
      maximum: 1024,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  proceedings: {
    length: {
      minimum: 3,
      maximum: 64,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  notification: {
    length: {
      minimum: 3,
      maximum: 64,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  comments: {
    length: {
      minimum: 3,
      maximum: 1024,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  clients: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const NewJobModal = ({ handleClose, loadUsers, visibility, users, clients, statuses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [selectedClient, setSelectedClient] = useState();
  const [plants, setPlants] = React.useState([]);
  const [selectedClientHasPlant, setSelectedClientHasPlant] = useState(false);
  const handleChangeClient = clientId => {
    loadUsers(clientId);
    setSelectedClient(clientId);
    setSelectedPlant();
    setSelectedUsers();
    clients.forEach(client => {
      if (client.id === clientId) {
        if (client.plants.length > 0) {
          setPlants(client.plants);
          setSelectedClientHasPlant(true);
        } else {
          setSelectedClientHasPlant(false);
        }
      }
    });
  };

  const [selectedPlant, setSelectedPlant] = useState();
  const handleChangePlant = plantId => {
    setSelectedPlant(plantId);
  };

  const [selectedStatus, setSelectedStatus] = useState(1);
  const handleChangeStatus = statusId => {
    setSelectedStatus(statusId);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleChangeUsers = users => {
    setSelectedUsers(users);
  };

  const [selectedNoticeDate, setSelectedNoticeDate] = React.useState(new Date().setHours(0, 0, 0, 0));
  const handleNoticeDateChange = date => {
    if (date > selectedWarningDate) {
      setSelectedWarningDate(date);
    }
    if (date > selectedExpireDate) {
      setSelectedExpireDate(date);
    }
    setSelectedNoticeDate(date);
  };

  const [selectedWarningDate, setSelectedWarningDate] = React.useState(new Date().setHours(0, 0, 0, 0));
  const handleWarningDateChange = date => {
    if (date < selectedNoticeDate) {
      setSelectedNoticeDate(date);
    }
    if (date > selectedExpireDate) {
      setSelectedExpireDate(date);
    }
    setSelectedWarningDate(date);
  };

  const [selectedExpireDate, setSelectedExpireDate] = React.useState(new Date().setHours(0, 0, 0, 0));
  const handleExpireDateChange = date => {
    if (date < selectedNoticeDate) {
      setSelectedNoticeDate(date);
    }
    if (date < selectedWarningDate) {
      setSelectedWarningDate(date);
    }
    setSelectedExpireDate(date);
  };

  const [files, setFiles] = useState([]);
  const handleChangeFiles = files => {
    setFiles(files);
  };

  const handleOnDocRejected = () => {
    enqueueSnackbar('Archivo no válido! Recordá que debe tener un tamaño máximo de 10mb.', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      variant: 'error'
    });
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [addingUser, setAddingUser] = useState(0);
  const [addingFile, setAddingFile] = useState(0);
  useEffect(() => {
    if (close && addingUser < 1 && addingFile < 1) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, addingUser, addingFile]);

  const handleCloseDismiss = () => {
    setSelectedClientHasPlant(false);
    handleClose(true);
  };

  const handleCreateJob = async event => {
    event.preventDefault();
    setLoading(true);
    Api.post('/jobs', {
      organism: formState.values.organism,
      description: formState.values.description,
      proceedings: formState.values.proceedings,
      notification: formState.values.notification,
      comments: formState.values.comments,
      notice_on: moment(selectedNoticeDate).format(),
      warn_on: moment(selectedWarningDate).format(),
      expire_on: moment(selectedExpireDate).format(),
      client_id: selectedClient,
      plant_id: selectedPlant,
      job_status_id: selectedStatus
    })
      .then(function(response) {
        setAddingUser(selectedUsers.length);
        selectedUsers.forEach(async user => {
          await createJobUsers(response.data.data.id, user.id);
        });
        setAddingFile(files.length);
        files.forEach(async file => {
          await createJobFiles(response.data.data.id, file);
        });
        setClose(true);
        formState.values.organism = '';
        formState.values.description = '';
        formState.values.comments = '';
        formState.values.proceedings = '';
        formState.values.notification = '';
        enqueueSnackbar('Vencimiento creado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const createJobUsers = async (jobId, userId) => {
    Api.post('/jobs_users', {
      job_id: jobId,
      user_id: userId
    })
      .then(function() {
        setAddingUser(addingUser - 1);
        return true;
      })
      .catch(function() {
        setAddingUser(addingUser - 1);
        return false;
      });
  };

  const createJobFiles = async (jobId, file) => {
    const data = new FormData();
    data.append('file', file);
    Api.post('/files/upload', data)
      .then(function(response) {
        Api.post('/jobs_files', {
          job_id: jobId,
          name: file.name,
          url: response.data.fileUrl
        });
        setAddingFile(addingFile - 1);
      })
      .catch(function() {
        setAddingFile(addingFile - 1);
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nuevo Vencimiento" maxWidth="md">
        <form onSubmit={handleCreateJob}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={selectedClientHasPlant ? 4 : 6}>
                <Autocomplete
                  name="clients"
                  options={clients}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeClient(value.id);
                    handleChange(option, 'clients');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Cliente" />}
                  error={hasError('clients')}
                  helperText={hasError('clients') ? formState.errors.clients[0] : null}
                />
              </Grid>
              {selectedClientHasPlant ? (
                <Grid item xs={4}>
                  <Autocomplete
                    name="plants"
                    key={selectedClient}
                    options={plants}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangePlant(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Planta" />}
                    error={hasError('plants')}
                    helperText={hasError('plants') ? formState.errors.plants[0] : null}
                  />
                </Grid>
              ) : null}
              <Grid item xs={selectedClientHasPlant ? 4 : 6}>
                <Autocomplete
                  name="statuses"
                  options={statuses}
                  defaultValue={statuses[0]}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeStatus(value.id);
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Estado" />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  error={hasError('organism')}
                  helperText={hasError('organism') ? formState.errors.organism[0] : null}
                  label="Organismo"
                  name="organism"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.organism || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  error={hasError('proceedings')}
                  helperText={hasError('proceedings') ? formState.errors.proceedings[0] : null}
                  label="Expediente"
                  name="proceedings"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.proceedings || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  error={hasError('notification')}
                  helperText={hasError('notification') ? formState.errors.notification[0] : null}
                  label="Notificación"
                  name="notification"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.notification || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <TextField
              className={styles.marginTop}
              multiline
              fullWidth
              error={hasError('description')}
              helperText={hasError('description') ? formState.errors.description[0] : null}
              label="Descripción"
              name="description"
              type="text"
              onChange={handleChange}
              value={formState.values.description || ''}
              variant="outlined"
            />
            <TextField
              className={styles.marginTop}
              multiline
              fullWidth
              error={hasError('comments')}
              helperText={hasError('comments') ? formState.errors.comments[0] : null}
              label="Comentarios"
              name="comments"
              type="text"
              onChange={handleChange}
              value={formState.values.comments || ''}
              variant="outlined"
            />
            <Grid container spacing={3} className={styles.marginTop}>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={momentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="noticeDate"
                    label="Fecha de Notificación"
                    value={selectedNoticeDate}
                    onChange={(handleChange, handleNoticeDateChange)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={momentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="warningDate"
                    label="Fecha de Alerta"
                    value={selectedWarningDate}
                    onChange={handleWarningDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider libInstance={moment} utils={momentUtils} locale="es">
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="expirationDate"
                    label="Fecha de Vencimiento"
                    value={selectedExpireDate}
                    onChange={handleExpireDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Autocomplete
              className={styles.marginTop}
              key={selectedClient}
              multiple
              name="users"
              options={users}
              getOptionLabel={option => option.fullname}
              filterSelectedOptions
              onChange={(option, value) => {
                handleChangeUsers(value);
              }}
              renderInput={params => <TextField {...params} variant="outlined" label="Usuarios a notificar" />}
            />
            <Card className={styles.marginTop}>
              <CardContent>
                <DropzoneArea
                  dropzoneClass={styles.dropzone}
                  acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/msword']}
                  onChange={handleChangeFiles}
                  showFileNames
                  dropzoneText="Arraste los archivos aquí o haga click para seleccionar"
                  showAlerts={false}
                  filesLimit={10}
                  maxFileSize={10000000}
                  onDropRejected={handleOnDocRejected}
                />
              </CardContent>
            </Card>
          </DialogContent>

          <DialogActions>
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default NewJobModal;
