import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import momentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';
import GenericToolbar from '../../../components/GenericToolbar/GenericToolbar';

import NewScheduleDetailModal from './NewScheduleDetailModal';

moment.locale('es');

const schema = {
  quotation_identifier: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 4,
      maximum: 64,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  clients: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const NewScheduleModal = ({ handleClose, visibility, clients, statuses, sectors, matrices, items, schedules }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortDirection: 'desc'
      }
    },
    {
      name: 'year',
      label: 'Año',
      options: {
        sort: true
      }
    },
    {
      name: 'month.name',
      label: 'Mes',
      options: {
        sort: true
      }
    },
    {
      name: 'sector.name',
      label: 'Sector',
      options: {
        sort: true
      }
    },
    {
      name: 'matrix.name',
      label: 'Matriz',
      options: {
        sort: true
      }
    },
    {
      name: 'item.name',
      label: 'Servicio / Trabajo',
      options: {
        sort: true
      }
    },
    {
      name: 'objective',
      label: 'Total',
      options: {
        sort: true
      }
    },
    {
      name: 'progress',
      label: 'Parcial',
      options: {
        sort: true
      }
    },
    {
      name: 'comment',
      label: 'Comentarios',
      options: {
        sort: true
      }
    }
  ];
  const options = {
    filter: false,
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    viewColumns: false,
    download: false,
    print: false,
    search: false,
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [scheduleDetails, setScheduleDetails] = useState([]);

  const [selectedClient, setSelectedClient] = useState();
  const [plants, setPlants] = React.useState([]);
  const [selectedClientHasPlant, setSelectedClientHasPlant] = useState(false);
  const handleChangeClient = clientId => {
    setSelectedClient(clientId);
    setSelectedPlant();
    clients.forEach(client => {
      if (client.id === clientId) {
        if (client.plants.length > 0) {
          setPlants(client.plants);
          setSelectedClientHasPlant(true);
        } else {
          setSelectedClientHasPlant(false);
        }
      }
    });
  };
  const [selectedScheduleToCopy, setSelectedScheduleToCopy] = useState([]);
  const handleChangeQuotationIdentifierCopy = quotation_identifier_copy => {
    schedules.forEach(schedule => {
      if (schedule.quotation_identifier === quotation_identifier_copy) {
        schedule.schedules_details.forEach(scheduleDetail => {
          var month = {
            id: scheduleDetail.month,
            name: scheduleDetail.month_name
          };
          scheduleDetail.month = month;
          scheduleDetail.progress = 0;
        });

        setSelectedScheduleToCopy(schedule);
      }
    });
  };

  useEffect(() => {
    setScheduleDetails(selectedScheduleToCopy.schedules_details);
    setSelectedClient(selectedScheduleToCopy.client_id);
    clients.forEach(client => {
      if (client.id === selectedScheduleToCopy.client_id) {
        if (client.plants.length > 0) {
          setPlants(client.plants);
          setSelectedClientHasPlant(true);
        } else {
          setSelectedClientHasPlant(false);
        }
      }
    });
    setSelectedPlant(selectedScheduleToCopy.plant);
    setSelectedStartDate(selectedScheduleToCopy.start_on);
    setSelectedEndDate(selectedScheduleToCopy.end_on);
  }, [selectedScheduleToCopy]);

  const [selectedPlant, setSelectedPlant] = useState();
  const handleChangePlant = plantId => {
    setSelectedPlant(plantId);
  };

  const [selectedStatus, setSelectedStatus] = useState(1);
  const handleChangeStatus = statusId => {
    setSelectedStatus(statusId);
  };

  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date().setHours(0, 0, 0, 0));
  const handleStartDateChange = date => {
    if (date > selectedEndDate) {
      setSelectedEndDate(date);
    }
    setSelectedStartDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date().setHours(0, 0, 0, 0));
  const handleEndDateChange = date => {
    if (date < selectedStartDate) {
      setSelectedStartDate(date);
    }
    setSelectedEndDate(date);
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [addingScheduleDetail, setAddingScheduleDetail] = useState(0);

  useEffect(() => {
    if (close && addingScheduleDetail < 1) {
      setScheduleDetails([]);
      setLoading(false);
      handleClose(true);
    }
  }, [close, addingScheduleDetail]);

  const [visibilityNewScheduleDetail, setVisibilityNewScheduleDetail] = useState(false);
  const handleClickOpenNewScheduleDetail = () => {
    setVisibilityNewScheduleDetail(true);
  };

  const handleCloseNewScheduleDetail = success => {
    setVisibilityNewScheduleDetail(false);
  };

  const handleCloseDismiss = () => {
    setSelectedClientHasPlant(false);
    setScheduleDetails([]);
    handleClose(true);
  };

  const handleCreateSchedule = async event => {
    event.preventDefault();
    setLoading(true);
    Api.post('/schedules', {
      quotation_identifier: formState.values.quotation_identifier,
      start_on: moment(selectedStartDate).format(),
      end_on: moment(selectedEndDate).format(),
      client_id: selectedClient,
      plant_id: selectedPlant,
      schedule_status_id: selectedStatus
    })
      .then(function(response) {
        setAddingScheduleDetail(scheduleDetails.length);
        scheduleDetails.forEach(async scheduleDetail => {
          await createScheduleDetail(response.data.data.id, scheduleDetail);
        });

        setClose(true);
        formState.values.quotation_identifier = '';
        enqueueSnackbar('Cronograma creado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const createScheduleDetail = async (scheduleId, scheduleDetail) => {
    Api.post('/schedules_details', {
      schedule_id: scheduleId,
      sector_id: scheduleDetail.sector.id,
      matrix_id: scheduleDetail.matrix.id,
      item_id: scheduleDetail.item.id,
      year: parseInt(scheduleDetail.year),
      month: scheduleDetail.month.id,
      objective: parseInt(scheduleDetail.objective),
      progress: parseInt(scheduleDetail.progress),
      comment: scheduleDetail.comment
    })
      .then(function() {
        setAddingScheduleDetail(addingScheduleDetail - 1);
        return true;
      })
      .catch(function() {
        setAddingScheduleDetail(addingScheduleDetail - 1);
        return false;
      });
  };

  const addNewDetailModal = schedulesDetails => {
    var new_schedules_details = [];
    if (scheduleDetails !== undefined) {
      new_schedules_details = scheduleDetails;
    }
    schedulesDetails.forEach(new_schedule_detail => {
      new_schedules_details.push(new_schedule_detail);
    });
    setScheduleDetails(new_schedules_details);
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nuevo Cronograma" maxWidth="lg">
        <form onSubmit={handleCreateSchedule}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={selectedClientHasPlant ? 4 : 6}>
                <Autocomplete
                  name="clients"
                  options={clients}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeClient(value.id);
                    handleChange(option, 'clients');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Cliente" />}
                  error={hasError('clients')}
                  helperText={hasError('clients') ? formState.errors.clients[0] : null}
                />
              </Grid>
              {selectedClientHasPlant ? (
                <Grid item xs={4}>
                  <Autocomplete
                    name="plants"
                    key={selectedClient}
                    options={plants}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangePlant(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Planta" />}
                    error={hasError('plants')}
                    helperText={hasError('plants') ? formState.errors.plants[0] : null}
                  />
                </Grid>
              ) : null}
              <Grid item xs={selectedClientHasPlant ? 4 : 6}>
                <Autocomplete
                  name="statuses"
                  options={statuses}
                  defaultValue={statuses[0]}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeStatus(value.id);
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Estado" />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  className={styles.marginTop}
                  error={hasError('quotation_identifier')}
                  helperText={hasError('quotation_identifier') ? formState.errors.quotation_identifier[0] : null}
                  label="Nro. de Cotización"
                  name="quotation_identifier"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.quotation_identifier || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={momentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="startDate"
                    label="Fecha de Inicio"
                    value={selectedStartDate}
                    onChange={(handleChange, handleStartDateChange)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={momentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="endDate"
                    label="Fecha de Fin"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <div className={styles.marginTop}>
              <GenericToolbar handleClickOpenNew={handleClickOpenNewScheduleDetail} />
              <MUIDataTable title={'Tareas'} data={scheduleDetails} columns={columns} options={options} />
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Autocomplete
              name="quotation_identifier_copy"
              options={schedules}
              getOptionLabel={option => option.quotation_identifier}
              filterSelectedOptions
              disableClearable
              onChange={(option, value) => {
                handleChangeQuotationIdentifierCopy(value.quotation_identifier);
              }}
              renderInput={params => <TextField {...params} variant="outlined" label="Si desea copiar un cronograma anterior ingrese número de cotización" />}
              inputProps={{ style: 'width:200px !important' }}
              style={{ width: '500px' }}
            />
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
      <NewScheduleDetailModal
        handleClose={handleCloseNewScheduleDetail}
        addNewDetailModal={addNewDetailModal}
        visibility={visibilityNewScheduleDetail}
        sectors={sectors}
        matrices={matrices}
        items={items}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
      />
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default NewScheduleModal;
