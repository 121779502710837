import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import JWTDecoder from 'jwt-decode';

import Api from '../../Api';
import UserModal from './components/UserModal';
import NewUserModal from './components/NewUserModal';
import GenericToolbar from '../../components/GenericToolbar/GenericToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Users = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'firstname',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'lastname',
      label: 'Apellido',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'role.name',
      label: 'Rol',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'client.name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'plant.name',
      label: 'Planta',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status.name',
      label: 'Estado',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    onRowClick: row => {
      handleClickOpen(row);
    },
    viewColumns: false,
    onTableChange: (action, tableState) => {
      switch (action) {
        /* .... */
        default:
          console.log('[' + action + '] action not handled.');
      }
    },
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [clients, setClients] = useState([]);
  const [userStatuses, setUserStatuses] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [visibilityNew, setVisibilityNew] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const handleClickOpenNew = () => {
    setVisibilityNew(true);
  };

  const handleClickOpen = row => {
    users.forEach(user => {
      if (user.id === row[0]) {
        setSelectedUser(user);
      }
    });
    setVisibility(true);
  };

  const handleCloseNew = () => {
    setVisibilityNew(false);
  };

  const handleClose = () => {
    setVisibility(false);
  };

  const loadUsers = () => {
    Api.get('/users')
      .then(function(response) {
        setUsers(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setUsers([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  useEffect(() => {
    loadUsers();
    Api.get('/roles')
      .then(function(response) {
        setRoles(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'No se obtuvieron roles';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
    Api.get('/clients')
      .then(function(response) {
        setClients(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/users_statuses')
      .then(function(response) {
        response.data.data.shift();
        setUserStatuses(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'No se obtuvieron estados de usuario';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  }, []);

  return (
    <div className={classes.root}>
      {jwtUser.u_role_id == 1 ? <GenericToolbar handleClickOpenNew={handleClickOpenNew} /> : null}
      <div className={classes.content}>
        <MUIDataTable title={'Lista de Usuarios'} data={users} columns={columns} options={options} />
      </div>
      <UserModal
        handleClose={handleClose}
        onSuccess={loadUsers}
        visibility={visibility}
        user={selectedUser}
        userStatuses={userStatuses}
        userRoles={roles}
        clients={clients}
      />
      <NewUserModal handleClose={handleCloseNew} onSuccess={loadUsers} visibility={visibilityNew} roles={roles} clients={clients} />
    </div>
  );
};

export default Users;
