import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import { DropzoneArea } from 'material-ui-dropzone';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

moment.locale('es');

const schema = {
  identifier: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 6,
      maximum: 64,
      message: '^Debe contener al menos 6 caracteres'
    }
  },
  months: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  quotation_identifier: {
    length: {
      minimum: 3,
      maximum: 64,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  clients: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  sectors: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  matrices: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  items: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const NewReportModal = ({ handleClose, visibility, clients, sectors, matrices, items }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [years, setYears] = useState([
    { id: 0, name: (parseInt(moment().format('YYYY')) - 1).toString() },
    { id: 1, name: moment().format('YYYY') },
    { id: 2, name: (parseInt(moment().format('YYYY')) + 1).toString() }
  ]);

  const [months, setMonths] = useState([
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' }
  ]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [selectedClient, setSelectedClient] = useState();
  const [plants, setPlants] = React.useState([]);
  const [selectedClientHasPlant, setSelectedClientHasPlant] = useState(false);
  const handleChangeClient = clientId => {
    setSelectedClient(clientId);
    setSelectedPlant();
    clients.forEach(client => {
      if (client.id === clientId) {
        if (client.plants.length > 0) {
          setPlants(client.plants);
          setSelectedClientHasPlant(true);
        } else {
          setSelectedClientHasPlant(false);
        }
      }
    });
  };

  const [selectedPlant, setSelectedPlant] = useState();
  const handleChangePlant = plantId => {
    setSelectedPlant(plantId);
  };

  const [selectedSector, setSelectedSector] = useState();
  const handleChangeSector = sectorId => {
    sectors.forEach(sector => {
      if (sector.id == sectorId) {
        setSelectedSector(sector);
      }
    });
  };

  const [selectedMatrix, setSelectedMatrix] = useState();
  const handleChangeMatrix = matrixId => {
    matrices.forEach(matrix => {
      if (matrix.id == matrixId) {
        setSelectedMatrix(matrix);
      }
    });
  };

  const [selectedItem, setSelectedItem] = useState();
  const handleChangeItem = itemId => {
    setSelectedItem(itemId);
  };

  const [selectedMonth, setSelectedMonth] = useState();
  const handleChangeMonth = monthId => {
    months.forEach(month => {
      if (month.id === monthId) {
        setSelectedMonth(month);
      }
    });
  };
  const [selectedYear, setSelectedYear] = useState(years[1]);
  const handleChangeYear = yearId => {
    years.forEach(year => {
      if (year.id === yearId) {
        setSelectedYear(year);
      }
    });
  };

  const [files, setFiles] = useState([]);
  const handleChangeFiles = files => {
    setFiles(files);
  };

  const handleOnDocRejected = () => {
    enqueueSnackbar('Archivo no válido! Recordá que debe tener un tamaño máximo de 15mb.', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      variant: 'error'
    });
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [addingFile, setAddingFile] = useState(0);
  useEffect(() => {
    if (close && addingFile < 1) {
      setClose(false);
      setLoading(false);
      handleClose(true);
      setFormState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      });
    }
  }, [close, addingFile]);

  const handleCloseDismiss = () => {
    setSelectedClientHasPlant(false);
    handleClose();
  };

  const handleCreateReport = async event => {
    event.preventDefault();
    setLoading(true);
    Api.post('/reports', {
      identifier: formState.values.identifier,
      year: parseInt(selectedYear.name),
      month: parseInt(selectedMonth.id),
      quotation_identifier: formState.values.quotation_identifier,
      sector_id: selectedSector.id,
      matrix_id: selectedMatrix.id,
      item_id: selectedItem,
      client_id: selectedClient,
      plant_id: selectedPlant
    })
      .then(function(response) {
        setAddingFile(files.length);
        files.forEach(async file => {
          await createReportFiles(response.data.data.id, file);
        });
        setClose(true);
        formState.values.identifier = '';
        formState.values.year = '';
        formState.values.month = '';
        formState.values.quotation_identifier = '';
        enqueueSnackbar('Informe creado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const createReportFiles = async (reportId, file) => {
    const data = new FormData();
    data.append('file', file);
    Api.post('/files/upload', data)
      .then(function(response) {
        Api.post('/reports_files', {
          report_id: reportId,
          name: file.name,
          url: response.data.fileUrl
        });
        setAddingFile(addingFile - 1);
      })
      .catch(function() {
        setAddingFile(addingFile - 1);
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nuevo Informe" maxWidth="md">
        <form onSubmit={handleCreateReport}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={selectedClientHasPlant ? 6 : 12}>
                <Autocomplete
                  name="clients"
                  options={clients}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeClient(value.id);
                    handleChange(option, 'clients');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Cliente" />}
                  error={hasError('clients')}
                  helperText={hasError('clients') ? formState.errors.clients[0] : null}
                />
              </Grid>
              {selectedClientHasPlant ? (
                <Grid item xs={6}>
                  <Autocomplete
                    name="plants"
                    key={selectedClient}
                    options={plants}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangePlant(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Planta" />}
                    error={hasError('plants')}
                    helperText={hasError('plants') ? formState.errors.plants[0] : null}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  error={hasError('identifier')}
                  helperText={hasError('identifier') ? formState.errors.identifier[0] : null}
                  label="Identificador"
                  name="identifier"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.identifier || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  error={hasError('quotation_identifier')}
                  helperText={hasError('quotation_identifier') ? formState.errors.quotation_identifier[0] : null}
                  label="Nro. de Cotización"
                  name="quotation_identifier"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.quotation_identifier || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  name="year"
                  options={years}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeYear(value.id);
                    handleChange(option, 'years');
                  }}
                  defaultValue={years[1]}
                  renderInput={params => <TextField {...params} variant="outlined" label="Año" />}
                  error={hasError('year')}
                  helperText={hasError('year') ? formState.errors.year[0] : null}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  name="month"
                  options={months}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeMonth(value.id);
                    handleChange(option, 'months');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Mes" />}
                  error={hasError('month')}
                  helperText={hasError('month') ? formState.errors.month[0] : null}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Autocomplete
                  name="sectors"
                  options={sectors}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeSector(value.id);
                    handleChange(option, 'sectors');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Sector" />}
                  error={hasError('sectors')}
                  helperText={hasError('sectors') ? formState.errors.sectors[0] : null}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={selectedSector == null ? 'disabled' : null}
                  name="matrices"
                  options={selectedSector == null ? matrices : selectedSector.matrices}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeMatrix(value.id);
                    handleChange(option, 'matrices');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Matriz" />}
                  error={hasError('matrices')}
                  helperText={hasError('matrices') ? formState.errors.matrices[0] : null}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={selectedMatrix == null ? 'disabled' : null}
                  name="items"
                  options={selectedMatrix == null ? items : selectedMatrix.items}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeItem(value.id);
                    handleChange(option, 'items');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Servicio / Trabajo" />}
                  error={hasError('items')}
                  helperText={hasError('items') ? formState.errors.items[0] : null}
                />
              </Grid>
            </Grid>

            <Card className={styles.marginTop}>
              <CardContent>
                <DropzoneArea
                  dropzoneClass={styles.dropzone}
                  acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/msword']}
                  onChange={handleChangeFiles}
                  showFileNames
                  dropzoneText="Arraste los archivos aquí o haga click para seleccionar"
                  showAlerts={false}
                  filesLimit={10}
                  maxFileSize={15000000}
                  onDropRejected={handleOnDocRejected}
                />
              </CardContent>
            </Card>
          </DialogContent>

          <DialogActions>
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default NewReportModal;
