import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import Api from '../../../Api';

const ReportFileItem = ({ file, fileDeleted}) => {
  const { enqueueSnackbar } = useSnackbar();
  const fileURL = file.url;
  const [confirmDeleteDialogVisibility, setConfirmDeleteDialogVisibility] = useState(false);
  const handleDeleteFile = async event => {
    event.preventDefault();
    setConfirmDeleteDialogVisibility(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisibility(false);
  };

  const handleDelete = () => {
    setConfirmDeleteDialogVisibility(true);
  };

  const handleOnConfirmDelete = () => {
    setConfirmDeleteDialogVisibility(false);
    handleDeleteReportFile();
  };

  const handleDeleteReportFile = () => {
    Api.delete('/reports_files/' + file.id)
      .then(function() {
        fileDeleted();
        enqueueSnackbar('Archivo "' + file.name + '" removido exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
        return false;
      });
  };

  return (
    <div>
      <ListItem key={file.id} button component={Link} to={{ pathname: fileURL }} target="_blank" style={{ textAlign: 'center' }}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary={file.name} />
        <Button onClick={handleDeleteFile} style={{ color: '#e91e63' }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
      <ConfirmDialog
        handleClose={handleCloseConfirmDeleteDialog}
        handleOnConfirm={handleOnConfirmDelete}
        visibility={confirmDeleteDialogVisibility}
        title="¿Está seguro que desea eliminar este archivo?"
        message="La eliminación de este archivo es permanente"
      />
    </div>
  );
};

export default ReportFileItem;
