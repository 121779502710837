import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const UserModal = ({ handleClose, onSuccess, visibility, user, userStatuses, userRoles, clients }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userModified, setUserModified] = useState(false);
  const [plants, setPlants] = React.useState([]);
  const [selectedClientHasPlant, setSelectedClientHasPlant] = useState(false);

  useEffect(() => {
    setSelectedPlant(user.plant);
    clients.forEach(client => {
      if (client.id === user.client_id) {
        if (client.plants.length > 0) {
          setPlants(client.plants);
          setSelectedClientHasPlant(true);
        } else {
          setSelectedClientHasPlant(false);
        }
      }
    });
  }, [user]);

  const [statusId, setStatusId] = useState(user.status_id);
  const handleChangeStatus = selectedStatusId => {
    if (selectedStatusId !== user.status_id) {
      setUserModified(true);
    } else {
      setUserModified(false);
    }
    setStatusId(selectedStatusId);
  };

  const [roleId, setRoleId] = useState(user.role_id);
  const handleChangeRole = selectedRoleId => {
    if (selectedRoleId !== user.role_id) {
      setUserModified(true);
    } else {
      setUserModified(false);
    }
    setRoleId(selectedRoleId);
  };

  const handleSaveUser = async event => {
    event.preventDefault();
    Api.put('/users/' + user.id, {
      status_id: statusId,
      role_id: roleId,
      plant_id: (selectedPlant != null ) ? selectedPlant.id : null
    })
      .then(function(response) {
        handleClose();
        onSuccess();
        enqueueSnackbar('¡Estado modificado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const [selectedPlant, setSelectedPlant] = useState();
  const handleChangePlant = plant => {
    setSelectedPlant(plant);
    setUserModified(true)
  };

  if (user.role !== undefined) {
    var userName = user.firstname + ' ' + user.lastname;
    return (
      <div>
        <Modal handleClose={handleClose} visibility={visibility} title={userName}>
          <form onSubmit={handleSaveUser}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    name="userStatuses"
                    options={userStatuses}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    defaultValue={user.status}
                    onChange={(option, value) => {
                      handleChangeStatus(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Estado" />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    name="userRoles"
                    options={userRoles}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    defaultValue={user.role}
                    disabled={user.role_id == 3}
                    onChange={(option, value) => {
                      handleChangeRole(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Rol" />}
                  />
                </Grid>
              </Grid>
              {user.role_id === 3 ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField fullWidth disabled label="Cliente" name="client" type="text" value={user.client.name} variant="outlined" />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      disabled={!selectedClientHasPlant}
                      name="plants"
                      options={plants}
                      getOptionLabel={option => option.name}
                      filterSelectedOptions
                      onChange={(option, value) => {
                        handleChangePlant(value);
                      }}
                      value={selectedPlant}
                      renderInput={params => <TextField {...params} variant="outlined" label="Planta" />}
                    />
                  </Grid>
                </Grid>
              ) : null }
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField fullWidth disabled label="E-Mail" name="email" type="text" value={user.email} variant="outlined" />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button disabled={!userModified} type="submit" color="primary">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Modal>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default UserModal;
