import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Api from '../../Api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const ConfirmEmail = props => {
  const UrlQueryStrings = props.location.search;
  const queryValues = queryString.parse(UrlQueryStrings);
  console.log(queryValues.token);

  const [state, setState] = useState({
    message: '...'
  });

  useEffect(() => {
    Api.post('/users/email/action/confirm', {
      token: queryValues.token
    })
      .then(function(response) {
        setState(state => ({
          ...state,
          message: 'Validaste correctamente tu email, ahora tu cuenta esta activa, ¡ya podes ingresar!'
        }));
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'No pudimos confirmar tu email, avisanos para que te podamos ayudar';
          if (error.response.data && error.response.data.code === 1098) {
            message = 'Ya habias confirmado tu email, ¡ingresar a tu cuenta cuando quieras!';
          }
        }
        setState(state => ({
          ...state,
          message: message
        }));
      });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography gutterBottom variant="h4">
              Confirmación de E-mail
            </Typography>

            <Typography variant="h1">{state.message}</Typography>

            <a href="/sign-in">
              <img alt="Pagina no encontrada" className={classes.image} src="/images/draws/email_confirm.svg" />
            </a>

            <Typography variant="subtitle2">Toca la imagen para ingresar</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfirmEmail;
