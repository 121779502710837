/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import JWTDecoder from 'jwt-decode';
import { Grid, Table, TableHead, TextField, TableBody, TableRow, TableCell } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Api from '../../Api';
import ScheduleModal from './components/ScheduleModal';
import NewScheduleModal from './components/NewScheduleModal';
import GenericToolbar from '../../components/GenericToolbar/GenericToolbar';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  cellWithoutPadding: {
    padding: theme.spacing(0)
  },
  cellRightBorder: { borderWidth: 1, borderColor: '#eeeeee', borderStyle: 'solid' } // or borderTop: '1px solid red'
}));

const Schedule = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }
  const [years, setYears] = useState([
    { id: 0, name: (parseInt(moment().format('YYYY')) - 1).toString() },
    { id: 1, name: moment().format('YYYY') },
    { id: 2, name: (parseInt(moment().format('YYYY')) + 1).toString() }
  ]);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'client.name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'plant.name',
      label: 'Planta',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'quotation_identifier',
      label: 'Nro. de Cotización',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status.name',
      label: 'Estado',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'start_on_formatted',
      label: 'Fecha de Inicio',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'end_on_formatted',
      label: 'Fecha de Fin',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: '',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(tableMeta)} />;
        }
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    expandableRows: true,
    expandableRowsHeader: true,
    setRowProps: row => {
      return {
        style: { background: 'whitesmoke' }
      };
    },
    renderExpandableRow: rowData => {
      //Obtengo toda la info del row seleccionado
      var scheduleDetails = [];
      schedules.forEach(schedule => {
        if (schedule.id === rowData[0]) {
          scheduleDetails = schedule.schedules_details;
        }
      });

      //Armo el array para la tabla
      var scheduleDetailsSummary = [];
      scheduleDetails.forEach(scheduleDetail => {
        var scheduleDetailSummaryItem = {};
        scheduleDetailsSummary.forEach(scheduleDetailSummary => {
          if (scheduleDetail.item_id == scheduleDetailSummary.item_id) {
            scheduleDetailSummaryItem = scheduleDetailSummary;
          }
        });
        if (scheduleDetail.year == selectedYear.name) {
          switch (scheduleDetail.month) {
            case 1:
              if (typeof scheduleDetailSummaryItem.january == 'undefined') {
                scheduleDetailSummaryItem.january = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.january.objective = scheduleDetailSummaryItem.january.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.january.progress = scheduleDetailSummaryItem.january.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.january.comment = scheduleDetailSummaryItem.january.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.january.progress >= scheduleDetailSummaryItem.january.objective) {
                scheduleDetailSummaryItem.january.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 0 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.january.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.january.comment != '') {
                scheduleDetailSummaryItem.january.haveComment = '•';
              }
              break;
            case 2:
              if (typeof scheduleDetailSummaryItem.february == 'undefined') {
                scheduleDetailSummaryItem.february = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.february.objective = scheduleDetailSummaryItem.february.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.february.progress = scheduleDetailSummaryItem.february.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.february.comment = scheduleDetailSummaryItem.february.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.february.progress >= scheduleDetailSummaryItem.february.objective) {
                scheduleDetailSummaryItem.february.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 1 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.february.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.february.comment != '') {
                scheduleDetailSummaryItem.february.haveComment = '•';
              }
              break;
            case 3:
              if (typeof scheduleDetailSummaryItem.march == 'undefined') {
                scheduleDetailSummaryItem.march = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.march.objective = scheduleDetailSummaryItem.march.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.march.progress = scheduleDetailSummaryItem.march.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.march.comment = scheduleDetailSummaryItem.march.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.march.progress >= scheduleDetailSummaryItem.march.objective) {
                scheduleDetailSummaryItem.march.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 2 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.march.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.march.comment != '') {
                scheduleDetailSummaryItem.march.haveComment = '•';
              }
              break;
            case 4:
              if (typeof scheduleDetailSummaryItem.april == 'undefined') {
                scheduleDetailSummaryItem.april = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.april.objective = scheduleDetailSummaryItem.april.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.april.progress = scheduleDetailSummaryItem.april.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.april.comment = scheduleDetailSummaryItem.april.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.april.progress >= scheduleDetailSummaryItem.april.objective) {
                scheduleDetailSummaryItem.april.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 3 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.april.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.april.comment != '') {
                scheduleDetailSummaryItem.april.haveComment = '•';
              }
              break;
            case 5:
              if (typeof scheduleDetailSummaryItem.may == 'undefined') {
                scheduleDetailSummaryItem.may = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.may.objective = scheduleDetailSummaryItem.may.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.may.progress = scheduleDetailSummaryItem.may.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.may.comment = scheduleDetailSummaryItem.may.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.may.progress >= scheduleDetailSummaryItem.may.objective) {
                scheduleDetailSummaryItem.may.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 4 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.may.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.may.comment != '') {
                scheduleDetailSummaryItem.may.haveComment = '•';
              }
              break;
            case 6:
              if (typeof scheduleDetailSummaryItem.june == 'undefined') {
                scheduleDetailSummaryItem.june = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.june.objective = scheduleDetailSummaryItem.june.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.june.progress = scheduleDetailSummaryItem.june.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.june.comment = scheduleDetailSummaryItem.june.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.june.progress >= scheduleDetailSummaryItem.june.objective) {
                scheduleDetailSummaryItem.june.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 5 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.june.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.june.comment != '') {
                scheduleDetailSummaryItem.june.haveComment = '•';
              }
              break;
            case 7:
              if (typeof scheduleDetailSummaryItem.july == 'undefined') {
                scheduleDetailSummaryItem.july = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.july.objective = scheduleDetailSummaryItem.july.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.july.progress = scheduleDetailSummaryItem.july.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.july.comment = scheduleDetailSummaryItem.july.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.july.progress >= scheduleDetailSummaryItem.july.objective) {
                scheduleDetailSummaryItem.july.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 6 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.july.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.july.comment != '') {
                scheduleDetailSummaryItem.july.haveComment = '•';
              }
              break;
            case 8:
              if (typeof scheduleDetailSummaryItem.august == 'undefined') {
                scheduleDetailSummaryItem.august = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.august.objective = scheduleDetailSummaryItem.august.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.august.progress = scheduleDetailSummaryItem.august.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.august.comment = scheduleDetailSummaryItem.august.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.august.progress >= scheduleDetailSummaryItem.august.objective) {
                scheduleDetailSummaryItem.august.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 7 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.august.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.august.comment != '') {
                scheduleDetailSummaryItem.august.haveComment = '•';
              }
              break;
            case 9:
              if (typeof scheduleDetailSummaryItem.september == 'undefined') {
                scheduleDetailSummaryItem.september = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.september.objective = scheduleDetailSummaryItem.september.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.september.progress = scheduleDetailSummaryItem.september.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.september.comment = scheduleDetailSummaryItem.september.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.september.progress >= scheduleDetailSummaryItem.september.objective) {
                scheduleDetailSummaryItem.september.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 8 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.september.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.september.comment != '') {
                scheduleDetailSummaryItem.september.haveComment = '•';
              }
              break;
            case 10:
              if (typeof scheduleDetailSummaryItem.october == 'undefined') {
                scheduleDetailSummaryItem.october = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.october.objective = scheduleDetailSummaryItem.october.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.october.progress = scheduleDetailSummaryItem.october.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.october.comment = scheduleDetailSummaryItem.october.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.october.progress >= scheduleDetailSummaryItem.october.objective) {
                scheduleDetailSummaryItem.october.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 9 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.october.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.october.comment) {
                scheduleDetailSummaryItem.october.haveComment = '•';
              }
              break;
            case 11:
              if (typeof scheduleDetailSummaryItem.november == 'undefined') {
                scheduleDetailSummaryItem.november = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.november.objective = scheduleDetailSummaryItem.november.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.november.progress = scheduleDetailSummaryItem.november.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.november.comment = scheduleDetailSummaryItem.november.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.november.progress >= scheduleDetailSummaryItem.november.objective) {
                scheduleDetailSummaryItem.november.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 10 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.november.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.november.comment) {
                scheduleDetailSummaryItem.november.haveComment = '•';
              }
              break;
            case 12:
              if (typeof scheduleDetailSummaryItem.december == 'undefined') {
                scheduleDetailSummaryItem.december = {
                  objective: scheduleDetail.objective,
                  progress: scheduleDetail.progress,
                  comment: scheduleDetail.comment,
                  haveComment: '',
                  backgroundColor: 'White',
                  separator: '/'
                };
              } else {
                scheduleDetailSummaryItem.december.objective = scheduleDetailSummaryItem.december.objective + scheduleDetail.objective;
                scheduleDetailSummaryItem.december.progress = scheduleDetailSummaryItem.december.progress + scheduleDetail.progress;
                scheduleDetailSummaryItem.december.comment = scheduleDetailSummaryItem.december.comment + '|' + scheduleDetail.comment;
              }
              if (scheduleDetailSummaryItem.december.progress >= scheduleDetailSummaryItem.december.objective) {
                scheduleDetailSummaryItem.december.backgroundColor = 'MediumSeaGreen';
              } else {
                if (new Date().getMonth() > 11 || scheduleDetail.year < new Date().getFullYear()) {
                  scheduleDetailSummaryItem.december.backgroundColor = 'Tomato';
                }
              }
              if (scheduleDetailSummaryItem.december.comment != '') {
                scheduleDetailSummaryItem.december.haveComment = '•';
              }
              break;

            default:
              break;
          }
          if (typeof scheduleDetailSummaryItem.item_id == 'undefined') {
            scheduleDetailSummaryItem.item_id = scheduleDetail.item_id;
            scheduleDetailSummaryItem.item_name = scheduleDetail.item.name;
            scheduleDetailsSummary.push(scheduleDetailSummaryItem);
          }
        }
      });
      function renderTableData() {
        return scheduleDetailsSummary.map(scheduleDetailSummaryItem => {
          var { item_name, january, february, march, april, may, june, july, august, september, october, november, december } = scheduleDetailSummaryItem; //destructuring
          if (typeof january == 'undefined') {
            january = { objective: '', progress: '', comment: '', separator: '' };
          }
          if (typeof february == 'undefined') {
            february = { objective: '', progress: '', comment: '' };
          }
          if (typeof march == 'undefined') {
            march = { objective: '', progress: '', comment: '' };
          }
          if (typeof april == 'undefined') {
            april = { objective: '', progress: '', comment: '' };
          }
          if (typeof may == 'undefined') {
            may = { objective: '', progress: '', comment: '' };
          }
          if (typeof june == 'undefined') {
            june = { objective: '', progress: '', comment: '' };
          }
          if (typeof july == 'undefined') {
            july = { objective: '', progress: '', comment: '' };
          }
          if (typeof august == 'undefined') {
            august = { objective: '', progress: '', comment: '' };
          }
          if (typeof september == 'undefined') {
            september = { objective: '', progress: '', comment: '' };
          }
          if (typeof october == 'undefined') {
            october = { objective: '', progress: '', comment: '' };
          }
          if (typeof november == 'undefined') {
            november = { objective: '', progress: '', comment: '' };
          }
          if (typeof december == 'undefined') {
            december = { objective: '', progress: '', comment: '' };
          }
          return (
            <TableRow>
              <TableCell>{item_name}</TableCell>
              <TableCell title={january.comment} bgcolor={january.backgroundColor} align="center" className={classes.cellRightBorder}>
                {january.progress}{january.separator}{january.objective} {january.haveComment}
              </TableCell>
              <TableCell title={february.comment} bgcolor={february.backgroundColor} align="center" className={classes.cellRightBorder}>
                {february.progress}{february.separator}{february.objective} {february.haveComment}
              </TableCell>
              <TableCell title={march.comment} bgcolor={march.backgroundColor} align="center" className={classes.cellRightBorder}>
                {march.progress}{march.separator}{march.objective} {march.haveComment}
              </TableCell>
              <TableCell title={april.comment} bgcolor={april.backgroundColor} align="center" className={classes.cellRightBorder}>
                {april.progress}{april.separator}{april.objective} {april.haveComment}
              </TableCell>
              <TableCell title={may.comment} bgcolor={may.backgroundColor} align="center" className={classes.cellRightBorder}>
                {may.progress}{may.separator}{may.objective} {may.haveComment}
              </TableCell>
              <TableCell title={june.comment} bgcolor={june.backgroundColor} align="center" className={classes.cellRightBorder}>
                {june.progress}{june.separator}{june.objective} {june.haveComment}
              </TableCell>
              <TableCell title={july.comment} bgcolor={july.backgroundColor} align="center" className={classes.cellRightBorder}>
                {july.progress}{july.separator}{july.objective} {july.haveComment}
              </TableCell>
              <TableCell title={august.comment} bgcolor={august.backgroundColor} align="center" className={classes.cellRightBorder}>
                {august.progress}{august.separator}{august.objective} {august.haveComment}
              </TableCell>
              <TableCell title={september.comment} bgcolor={september.backgroundColor} align="center" className={classes.cellRightBorder}>
                {september.progress}{september.separator}{september.objective} {september.haveComment}
              </TableCell>
              <TableCell title={october.comment} bgcolor={october.backgroundColor} align="center" className={classes.cellRightBorder}>
                {october.progress}{october.separator}{october.objective} {october.haveComment}
              </TableCell>
              <TableCell title={november.comment} bgcolor={november.backgroundColor} align="center" className={classes.cellRightBorder}>
                {november.progress}{november.separator}{november.objective} {november.haveComment}
              </TableCell>
              <TableCell title={december.comment} bgcolor={december.backgroundColor} align="center" className={classes.cellRightBorder}>
                {december.progress}{december.separator}{december.objective} {december.haveComment}
              </TableCell>
            </TableRow>
          );
        });
      }
      const colSpan = rowData.length + 1;
      return (
        <TableCell colSpan={colSpan} className={classes.cellWithoutPadding}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Servicio / Trabajo</TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  ENE
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  FEB
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  MAR
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  ABR
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  MAY
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  JUN
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  JUL
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  AGO
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  SEP
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  OCT
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  NOV
                </TableCell>
                <TableCell align="center" className={classes.cellRightBorder}>
                  DIC
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableData()}</TableBody>
          </Table>
        </TableCell>
      );
    },
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [visibility, setVisibility] = useState(false);
  const [visibilityNew, setVisibilityNew] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState({});

  const [selectedYear, setSelectedYear] = useState(years[1]);
  const handleChangeYear = yearId => {
    years.forEach(year => {
      if (year.id === yearId) {
        setSelectedYear(year);
        loadSchedules(year)
      }
    });
  };

  const handleClickOpen = table => {
    schedules.forEach(schedule => {
      if (schedule.id === table.rowData[0]) {
        setSelectedSchedule(schedule);
      }
    });
    setVisibility(true);
  };

  const handleClickOpenNew = () => {
    setVisibilityNew(true);
  };

  const handleCloseNew = success => {
    if (success) {
      loadSchedules();
    }
    setVisibilityNew(false);
  };

  const handleClose = success => {
    loadSchedules();
    setVisibility(false);
  };

  const [schedules, setSchedules] = useState([]);
  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [matrices, setMatrices] = useState([]);
  const [items, setItems] = useState([]);

  const loadSchedules = (year) => {
    var url = '/schedules'
    if (typeof year !== 'undefined'){
      url = '/schedules?year=' + year.name
    } else {
      url = '/schedules?year=' + selectedYear.name
    }
    Api.get(url)
      .then(function(response) {
        response.data.data.forEach(r => {
          r.start_on_formatted = moment(r.start_on).format('DD/MM/YYYY');
          r.schedules_details.forEach(schedule_detail => {
            switch (schedule_detail.month) {
              case 1:
                schedule_detail.month_name = 'Enero';
                break;
              case 2:
                schedule_detail.month_name = 'Febrero';
                break;
              case 3:
                schedule_detail.month_name = 'Marzo';
                break;
              case 4:
                schedule_detail.month_name = 'Abril';
                break;
              case 5:
                schedule_detail.month_name = 'Mayo';
                break;
              case 6:
                schedule_detail.month_name = 'Junio';
                break;
              case 7:
                schedule_detail.month_name = 'Julio';
                break;
              case 8:
                schedule_detail.month_name = 'Agosto';
                break;
              case 9:
                schedule_detail.month_name = 'Septiembre';
                break;
              case 10:
                schedule_detail.month_name = 'Octubre';
                break;
              case 11:
                schedule_detail.month_name = 'Noviembre';
                break;
              case 12:
                schedule_detail.month_name = 'Diciembre';
                break;

              default:
                break;
            }
          });
        });
        response.data.data.forEach(r => {
          r.end_on_formatted = moment(r.end_on).format('DD/MM/YYYY');
        });

        setSchedules(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setSchedules([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const loadSelectedSchedule = (year) => {
    var url = '/schedules/' + selectedSchedule.id
    if (typeof year !== 'undefined'){
      url = '/schedules/' + selectedSchedule.id + '?year=' + year.name
    } else {
      url = '/schedules/' + selectedSchedule.id + '?year=' + selectedYear.name
    }
    Api.get(url)
      .then(function(response) {
        response.data.data.start_on_formatted = moment(response.data.data.start_on).format('DD/MM/YYYY');
        response.data.data.schedules_details.forEach(schedule_detail => {
          switch (schedule_detail.month) {
            case 1:
              schedule_detail.month_name = 'Enero';
              break;
            case 2:
              schedule_detail.month_name = 'Febrero';
              break;
            case 3:
              schedule_detail.month_name = 'Marzo';
              break;
            case 4:
              schedule_detail.month_name = 'Abril';
              break;
            case 5:
              schedule_detail.month_name = 'Mayo';
              break;
            case 6:
              schedule_detail.month_name = 'Junio';
              break;
            case 7:
              schedule_detail.month_name = 'Julio';
              break;
            case 8:
              schedule_detail.month_name = 'Agosto';
              break;
            case 9:
              schedule_detail.month_name = 'Septiembre';
              break;
            case 10:
              schedule_detail.month_name = 'Octubre';
              break;
            case 11:
              schedule_detail.month_name = 'Noviembre';
              break;
            case 12:
              schedule_detail.month_name = 'Diciembre';
              break;

            default:
              break;
          }
        });
        
        response.data.data.end_on_formatted = moment(response.data.data.end_on).format('DD/MM/YYYY');
        

        setSelectedSchedule(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setSelectedSchedule([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  useEffect(() => {
    loadSchedules();
    Api.get('/clients')
      .then(function(response) {
        setClients(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });

    Api.get('/schedules_statuses')
      .then(function(response) {
        setStatuses(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setStatuses([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/sectors')
      .then(function(response) {
        setSectors(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setSectors([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/matrices')
      .then(function(response) {
        setMatrices(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setMatrices([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/items')
      .then(function(response) {
        setItems(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setItems([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Autocomplete
            name="year"
            options={years}
            getOptionLabel={option => option.name}
            filterSelectedOptions
            disableClearable
            onChange={(option, value) => {
              handleChangeYear(value.id);
            }}
            defaultValue={selectedYear}
            renderInput={params => <TextField {...params} variant="outlined" label="Año" />}
          />
        </Grid>
        {jwtUser.u_role_id !== 3 ? (
          <Grid item xs={10}>
            <GenericToolbar handleClickOpenNew={handleClickOpenNew} />
          </Grid>
        ) : null}
      </Grid>
      <div className={classes.content}>
        <MUIDataTable title={'Cronogramas'} data={schedules} columns={columns} options={options} />
      </div>
      <ScheduleModal
        handleClose={handleClose}
        logedUser={jwtUser}
        loadSelectedSchedule={loadSelectedSchedule}
        visibility={visibility}
        schedule={selectedSchedule}
        statuses={statuses}
        loadSchedules={loadSchedules}
        sectors={sectors}
        matrices={matrices}
        items={items}
      />
      <NewScheduleModal
        handleClose={handleCloseNew}
        visibility={visibilityNew}
        clients={clients}
        statuses={statuses}
        sectors={sectors}
        matrices={matrices}
        items={items}
        schedules={schedules}
      />
    </div>
  );
};

export default Schedule;
