import React, { useState, useEffect, Controller } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';
import EquipmentCertificateFileItem from './EquipmentCertificateFileItem';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { Report } from '@material-ui/icons';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const EquipmentCertificateModal = ({ handleClose, logedUser, visibility, equipmentCertificate, equipmentCertificateFiles }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [deletingEquipmentCertificateID, setDeletingEquipmentCertificateID] = useState(0);
  const [deletingFile, setDeletingFile] = useState(0);
  const [confirmDeleteDialogVisibility, setConfirmDeleteDialogVisibility] = useState(false);

  useEffect(() => {
    if (deletingEquipmentCertificateID !== 0 && deletingFile < 1) {
      deleteEquipmentCertificate(deletingEquipmentCertificateID);
    }
    if (close && deletingFile < 1) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, deletingFile]);

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisibility(false);
  };

  const handleDelete = () => {
    setConfirmDeleteDialogVisibility(true);
  };

  const handleOnConfirmDelete = () => {
    setConfirmDeleteDialogVisibility(false);
    handleDeleteEquipmentCertificate();
  };

  const handleCloseDismiss = () => {
    handleClose();
  };

  const handleDeleteEquipmentCertificate = () => {
    setLoading(true);

    if (equipmentCertificate.files !== undefined) {
      setDeletingFile(equipmentCertificate.files.length);
      equipmentCertificate.files.forEach(async file => {
        await deleteEquipmentCertificateFiles(file, true);
      });
    }
    setDeletingEquipmentCertificateID(equipmentCertificate.id);
  };

  const deleteEquipmentCertificate = async equipmentCertificateId => {
    Api.delete('/equipments_certificates/' + equipmentCertificateId)
      .then(function() {
        enqueueSnackbar('Certificación de Equipo eliminada exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
        setClose(true);
      })
      .catch(function() {
        return false;
      });
  };

  const deleteEquipmentCertificateFiles = async (file, inReportDelete = false) => {
    Api.delete('/equipments_certificates_files/' + file.id)
      .then(function() {
        setDeletingFile(deletingFile - 1);
        if (!inReportDelete) {
          enqueueSnackbar('Archivo "' + file.name + '" removido exitosamente!', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success'
          });
        }
      })
      .catch(function(error) {
        setDeletingFile(deletingFile - 1);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
        return false;
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Certificado de Equipo">
        <form>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  className={styles.marginTop}
                  disabled
                  fullWidth
                  label="Equipo"
                  name="equipment_name"
                  type="text"
                  value={equipmentCertificate.equipment_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={styles.marginTop}
                  disabled
                  fullWidth
                  label="Año de certificación"
                  name="certificate_year"
                  type="text"
                  value={equipmentCertificate.certificate_year || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Card className={styles.marginTop}>
              {equipmentCertificateFiles.length > 0 ? (
                <List subheader={<ListSubheader>Archivos</ListSubheader>}>
                  {equipmentCertificateFiles.map((file, i) => {
                    return <EquipmentCertificateFileItem key={i} file={file} />;
                  })}
                </List>
              ) : null}
            </Card>
          </DialogContent>
          {logedUser.u_role_id !== 3 ? (
            <DialogActions>
              <div style={{ flex: '1 0 0' }} />
              <Button onClick={handleDelete} style={{ color: 'red' }}>
                Eliminar
              </Button>
            </DialogActions>
          ) : null}
        </form>
        <ConfirmDialog
          handleClose={handleCloseConfirmDeleteDialog}
          handleOnConfirm={handleOnConfirmDelete}
          visibility={confirmDeleteDialogVisibility}
          title="¿Está seguro que desea eliminar este informe?"
          message="La eliminación de este informe es permanente"
        />
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default EquipmentCertificateModal;
