import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import JWTDecoder from 'jwt-decode';

import Api from '../../Api';
import NewSectorModal from './components/NewSectorModal';
import NewMatrixModal from './components/NewMatrixModal';
import NewItemModal from './components/NewItemModal';
import GenericToolbar from '../../components/GenericToolbar/GenericToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const Configurations = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const matricesColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'sector.name',
      label: 'Sector',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const itemsColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'matrix.sector.name',
      label: 'Sector',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'matrix.name',
      label: 'Tipo / Matriz',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const options = {
    filter: false,
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    viewColumns: false,
    download: false,
    print: false,
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [visibilityNewSector, setVisibilityNewSector] = useState(false);

  const handleClickOpenNewSector = () => {
    setVisibilityNewSector(true);
  };

  const handleCloseNewSector = success => {
    if (success) {
      loadSectors();
    }
    setVisibilityNewSector(false);
  };

  const [sectors, setSectors] = useState([]);

  const loadSectors = () => {
    Api.get('/sectors')
      .then(function(response) {
        setSectors(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setSectors([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const [visibilityNewMatrix, setVisibilityNewMatrix] = useState(false);

  const handleClickOpenNewMatrix = () => {
    setVisibilityNewMatrix(true);
  };

  const handleCloseNewMatrix = success => {
    if (success) {
      loadMatrices();
    }
    setVisibilityNewMatrix(false);
  };

  const [matrices, setMatrices] = useState([]);

  const loadMatrices = () => {
    Api.get('/matrices')
      .then(function(response) {
        setMatrices(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setMatrices([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const [visibilityNewItem, setVisibilityNewItem] = useState(false);

  const handleClickOpenNewItem = () => {
    setVisibilityNewItem(true);
  };

  const handleCloseNewItem = success => {
    if (success) {
      loadItems();
    }
    setVisibilityNewItem(false);
  };

  const [items, setItems] = useState([]);

  const loadItems = () => {
    Api.get('/items')
      .then(function(response) {
        setItems(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setItems([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  useEffect(() => {
    loadSectors();
    loadMatrices();
    loadItems();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          {jwtUser.u_role_id !== 3 ? <GenericToolbar handleClickOpenNew={handleClickOpenNewSector} /> : null}
          <div className={classes.content}>
            <MUIDataTable title={'Sectores'} data={sectors} columns={columns} options={options} />
          </div>
          <NewSectorModal handleClose={handleCloseNewSector} visibility={visibilityNewSector} />
        </Grid>
        <Grid item xs={4}>
          {jwtUser.u_role_id !== 3 ? <GenericToolbar handleClickOpenNew={handleClickOpenNewMatrix} /> : null}
          <div className={classes.content}>
            <MUIDataTable title={'Tipos / Matrices'} data={matrices} columns={matricesColumns} options={options} />
          </div>
          <NewMatrixModal handleClose={handleCloseNewMatrix} visibility={visibilityNewMatrix} sectors={sectors} />
        </Grid>
        <Grid item xs={5}>
          {jwtUser.u_role_id !== 3 ? <GenericToolbar handleClickOpenNew={handleClickOpenNewItem} /> : null}
          <div className={classes.content}>
            <MUIDataTable title={'Servicios / Trabajos'} data={items} columns={itemsColumns} options={options} />
          </div>
          <NewItemModal handleClose={handleCloseNewItem} visibility={visibilityNewItem} sectors={sectors} matrices={matrices} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Configurations;
