import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';
import GenericToolbar from '../../../components/GenericToolbar/GenericToolbar';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const PlantModal = ({ handleClose, onSuccess, visibility, plant }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState(plant.name);
  const [contact, setContact] = useState(plant.contact);
  const [email, setEmail] = useState(plant.email);
  const [phone, setPhone] = useState(plant.phone);
  const [state, setState] = useState(plant.state);
  const [city, setCity] = useState(plant.city);
  const [address, setAddress] = useState(plant.address);

  useEffect(() => {
    setName(plant.name);
    setContact(plant.contact);
    setEmail(plant.email)
    setPhone(plant.phone)
    setState(plant.state)
    setCity(plant.city)
    setAddress(plant.address)
  }, [plant]);

  const handleSavePlant = async event => {
    event.preventDefault();
    Api.put('/plants/' + plant.id, {
      name: name,
      contact: contact,
      email: email,
      phone: phone,
      address: address
    })
      .then(function(response) {
        handleClose();
        onSuccess();
        enqueueSnackbar('¡Estado modificado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const handleChangeName = (event, name) => {
    if (event.target.value === '') {
      setName(null);
    } else {
      setName(event.target.value);
    }
  };
  const handleChangeContact = (event, name) => {
    if (event.target.value === '') {
      setContact(null);
    } else {
      setContact(event.target.value);
    }
  };
  const handleChangeEmail = (event, name) => {
    if (event.target.value === '') {
      setEmail(null);
    } else {
      setEmail(event.target.value);
    }
  };
  const handleChangePhone = (event, name) => {
    if (event.target.value === '') {
      setPhone(null);
    } else {
      setPhone(event.target.value);
    }
  };
  const handleChangeState = (event, name) => {
    if (event.target.value === '') {
      setState(null);
    } else {
      setState(event.target.value);
    }
  };
  const handleChangeCity = (event, name) => {
    if (event.target.value === '') {
      setCity(null);
    } else {
      setCity(event.target.value);
    }
  };
  const handleChangeAddress = (event, name) => {
    if (event.target.value === '') {
      setAddress(null);
    } else {
      setAddress(event.target.value);
    }
  };

  return (
    <div>
      <Modal handleClose={handleClose} visibility={visibility} title="Nueva Planta">
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth label="Nombre" name="name" type="text" value={name || ''} onChange={handleChangeName} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Nombre de Contacto" name="contact" type="text" value={contact || ''} onChange={handleChangeContact} variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth label="E-Mail" name="email" type="text" value={email || ''} onChange={handleChangeEmail} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Número de teléfono" name="phone" type="text" value={phone || ''} onChange={handleChangePhone} variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth disabled label="Provincia" name="state" type="text" value={state || ''} onChange={handleChangeState} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth disabled label="Localidad" name="city" type="text" value={city || ''} onChange={handleChangeCity} variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth label="Dirección" name="address" type="text" value={address || ''} onChange={handleChangeAddress} variant="outlined" />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleSavePlant}>
            Guardar
          </Button>
        </DialogActions>
      </Modal>
    </div>
  );
};

export default PlantModal;
