import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';

import Api from '../../../../Api';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {}
}));

const LatestJobsHistories = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'job.client.name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'job.organism',
      label: 'Organismo',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'job.proceedings',
      label: 'Expediente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'job.notification',
      label: 'Notificación',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'job.expire_on_formatted',
      label: 'Fecha de Vencimiento',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'user.firstname',
      label: 'Usuario',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'job_status_before.name',
      label: 'Estado Anterior',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'job_status_after.name',
      label: 'Estado Actual',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'created_on_formatted',
      label: 'Fecha de Modificación',
      options: {
        filter: false,
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };
  const [jobsHistories, setJobsHistories] = useState([]);

  useEffect(() => {
    Api.get('/jobs_histories')
      .then(function(response) {
        response.data.data.forEach(r => {
          r.job.expire_on_formatted = moment(r.job.expire_on).format('DD/MM/YYYY');
          r.created_on_formatted = moment(r.created_on).format('DD/MM/YYYY HH:mm');
        });
        setJobsHistories(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setJobsHistories([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            variant: 'error'
          });
        }
      });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MUIDataTable title={'Últimos cambios de estado'} data={jobsHistories} columns={columns} options={options} />
      </div>
    </div>
  );
};

export default LatestJobsHistories;
