import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';

const JobFileItem = ({ key, handleConfirmDeleteJobFile, logedUserRoleId, file }) => {
  const [visibility, setVisibility] = React.useState(false);
  const fileURL = file.url;
  const confirmMessage = 'El archivio "' + file.name + '" será eliminado de forma permanente';

  const handleOpenConfirmDialog = () => {
    setVisibility(true);
  };
  const handleClose = () => {
    setVisibility(false);
  };

  const handleOnConfirm = () => {
    setVisibility(false);
    handleConfirmDeleteJobFile(file);
  };

  return (
    <div>
      <ListItem key={file.id} button component={Link} to={{ pathname: fileURL }} target="_blank" style={{ textAlign: 'center' }}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary={file.name} />
        {logedUserRoleId !== 3 ? (
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={handleOpenConfirmDialog}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
      <ConfirmDialog
        handleClose={handleClose}
        handleOnConfirm={handleOnConfirm}
        visibility={visibility}
        title="¿Está seguro que desea eliminar este archivo?"
        message={confirmMessage}
      />
    </div>
  );
};

export default JobFileItem;
