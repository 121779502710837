import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import JWTDecoder from 'jwt-decode';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [state, setState] = useState({
    user: {
      name: 'Usuario',
      email: 'Cliente'
    }
  });

  useEffect(() => {
    const jwt = localStorage.getItem('access-jwt');
    if (jwt !== null) {
      const jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
      setState(state => ({
        ...state,
        user: {
          name: jwtUser.u_complete_name,
          email: jwtUser.u_email
        }
      }));
    }
  }, []);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography className={classes.name} variant="h4">
        {state.user.name}
      </Typography>
      <Typography variant="body2">{state.user.email}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
