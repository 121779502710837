import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import JWTDecoder from 'jwt-decode';

import Api from '../../Api';
import ClientModal from './components/ClientModal';
import NewClientModal from './components/NewClientModal';
import GenericToolbar from '../../components/GenericToolbar/GenericToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Clients = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'name',
      label: 'Razon Social',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'document',
      label: 'CUIT',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'state',
      label: 'Provincia',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'city',
      label: 'Localidad',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'address',
      label: 'Direccion',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'phone',
      label: 'Telefono',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',

    onRowClick: row => {
      handleClickOpen(row);
    },
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [clients, setClients] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [visibilityNew, setVisibilityNew] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  const handleClickOpenNew = () => {
    setVisibilityNew(true);
  };

  const handleClickOpen = row => {
    clients.forEach(client => {
      if (client.id === row[0]) {
        setSelectedClient(client);
      }
    });
    setVisibility(true);
  };

  const handleCloseNew = success => {
    if (success) {
      loadClients();
    }
    setVisibilityNew(false);
  };

  const handleClose = () => {
    setVisibility(false);
    loadClients();
  };

  const loadClients = () => {
    Api.get('/clients')
      .then(function(response) {
        setClients(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const loadSelectedClient = () => {
    Api.get('/clients/' + selectedClient.id)
      .then(function(response) {
        setSelectedClient(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const loadStates = () => {
    Api.get('/states')
      .then(function(response) {
        setStates(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const loadCities = stateId => {
    Api.get('/cities/state/' + stateId)
      .then(function(response) {
        setCities(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  useEffect(() => {
    loadClients();
    loadStates();
  }, []);

  return (
    <div className={classes.root}>
      {jwtUser.u_role_id == 1 ? <GenericToolbar handleClickOpenNew={handleClickOpenNew} /> : null}
      <div className={classes.content}>
        <MUIDataTable title={'Lista de Clientes'} data={clients} columns={columns} options={options} />
      </div>
      <ClientModal
        handleClose={handleClose}
        onSuccess={loadClients}
        loadSelectedClient={loadSelectedClient}
        loadCities={loadCities}
        visibility={visibility}
        client={selectedClient}
        states={states}
        cities={cities}
      />
      <NewClientModal handleClose={handleCloseNew} loadCities={loadCities} visibility={visibilityNew} states={states} cities={cities} />
    </div>
  );
};

export default Clients;
