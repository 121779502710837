import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const ReportFileItem = ({ key, file }) => {
  const fileURL = file.url;

  return (
    <div>
      <ListItem key={file.id} button component={Link} to={{ pathname: fileURL }} target="_blank" style={{ textAlign: 'center' }}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary={file.name} />
      </ListItem>
    </div>
  );
};

export default ReportFileItem;
