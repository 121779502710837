import React, { useState, useEffect, Controller } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';
import ReportFileItem from './ReportFileItem';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { Report } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const ReportModal = ({ handleClose, logedUser, visibility, report, reportFiles, loadSelectedReport }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [deletingReportID, setDeletingReportID] = useState(0);
  const [deletingFile, setDeletingFile] = useState(0);
  const [addFiles, setAddFiles] = useState([]);
  const [modified, setModified] = useState(false);
  const [confirmDeleteDialogVisibility, setConfirmDeleteDialogVisibility] = useState(false);
  const [confirmArchiveDialogVisibility, setConfirmArchiveDialogVisibility] = useState(false);
  const [addingFile, setAddingFile] = useState(0);
  useEffect(() => {
    if (deletingReportID !== 0 && deletingFile < 1) {
      deleteReport(deletingReportID);
    }
    if (close && deletingFile < 1 && addingFile < 1) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, deletingFile, addingFile, deletingReportID]);

  useEffect(() => {
    if (addFiles.length === 0) {
      setModified(false);
    } else {
      setModified(true);
    }
  }, [addFiles]);

  const [reportFile, setReportFile] = useState(0);
  useEffect(() => {
    setReportFile(reportFiles)
  }, [reportFiles]);

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisibility(false);
  };

  const handleDelete = () => {
    setConfirmDeleteDialogVisibility(true);
  };

  const handleOnConfirmDelete = () => {
    setConfirmDeleteDialogVisibility(false);
    handleDeleteReport();
  };

  const handleCloseConfirmArchiveDialog = () => {
    setConfirmArchiveDialogVisibility(false);
  };

  const handleArchive = () => {
    setConfirmArchiveDialogVisibility(true);
  };

  const handleOnConfirmArchive = () => {
    setConfirmArchiveDialogVisibility(false);
    handleArchiveReport();
  };

  const handleCloseDismiss = () => {
    handleClose();
  };

  const handleChangeFiles = files => {
    setAddFiles(files);
  };

  const handleOnDocRejected = () => {
    enqueueSnackbar('Archivo no válido! Recordá que debe tener un tamaño máximo de 15mb.', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      variant: 'error'
    });
  };

  const handleSaveReport = async event => {
    event.preventDefault();
    setLoading(true);

    setAddingFile(addFiles.length);
    for (const file of addFiles) {
      await createReportFiles(report.id, file);
    }
    setClose(true);
  };

  const handleDeleteReport = () => {
    setLoading(true);

    if (report.files !== undefined) {
      if (report.files.length > 0) {
        setDeletingFile(report.files.length);
        report.files.forEach(async file => {
          await deleteReportFiles(file, true);
        });
      }
    }
    setDeletingReportID(report.id);
  };

  const deleteReport = async reportId => {
    Api.delete('/reports/' + reportId)
      .then(function() {
        setDeletingReportID(0)
        enqueueSnackbar('Informe eliminado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
        setClose(true);
      })
      .catch(function() {
        return false;
      });
  };

  const deleteReportFiles = async (file, inReportDelete = false) => {
    Api.delete('/reports_files/' + file.id)
      .then(function() {
        setDeletingFile(deletingFile - 1);
        if (!inReportDelete) {
          enqueueSnackbar('Archivo "' + file.name + '" removido exitosamente!', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success'
          });
        }
      })
      .catch(function(error) {
        setDeletingFile(deletingFile - 1);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
        return false;
      });
  };

  const handleArchiveReport = () => {
    Api.put('/reports/' + report.id, { archived: true })
      .then(function(response) {
        handleClose(true);
        enqueueSnackbar('Informe archivado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const createReportFiles = async (reportId, file) => {
    const data = new FormData();
    data.append('file', file);
    Api.post('/files/upload', data)
      .then(function(response) {
        Api.post('/reports_files', {
          report_id: reportId,
          name: file.name,
          url: response.data.fileUrl
        });
        setAddingFile(addingFile - 1);
      })
      .catch(function() {
        setAddingFile(addingFile - 1);
      });
  };

  if (report.client !== undefined) {
    return (
      <div>
        <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Informe" maxWidth="md">
          <form onSubmit={handleSaveReport}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    className={styles.marginTop}
                    disabled
                    fullWidth
                    label="Identificador"
                    name="identifier"
                    type="text"
                    value={report.identifier || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={styles.marginTop}
                    disabled
                    fullWidth
                    label="Nro. de Cotización"
                    name="quotation_identifier"
                    type="text"
                    value={report.quotation_identifier || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField className={styles.marginTop} disabled fullWidth label="Año" name="year" type="text" value={report.year || ''} variant="outlined" />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={styles.marginTop}
                    disabled
                    fullWidth
                    label="Mes"
                    name="month"
                    type="text"
                    value={report.month || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {!report.plant ? (
                    <Card className={styles.marginTop}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Cliente" name="client" type="text" value={report.client.name || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              disabled
                              fullWidth
                              label="Documento"
                              name="document"
                              type="text"
                              value={report.client.document || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Teléfono" name="phone" type="text" value={report.client.phone || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField disabled fullWidth label="E-mail" name="email" type="text" value={report.client.email || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Provincia" name="state" type="text" value={report.client.state || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Localidad" name="city" type="text" value={report.client.city || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Dirección" name="address" type="text" value={report.client.address || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card className={styles.marginTop}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Planta" name="client" type="text" value={report.plant.name || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Contacto" name="document" type="text" value={report.plant.contact || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Teléfono" name="phone" type="text" value={report.plant.phone || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField disabled fullWidth label="E-mail" name="email" type="text" value={report.plant.email || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Provincia" name="state" type="text" value={report.plant.state || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Localidad" name="city" type="text" value={report.plant.city || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Dirección" name="address" type="text" value={report.plant.address || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
              {logedUser.u_role_id !== 3 && !report.archived ? (
              <Card className={styles.marginTop}>
                <CardContent>
                  <DropzoneArea
                    dropzoneClass={styles.dropzone}
                    acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/msword']}
                    onChange={handleChangeFiles}
                    showFileNames
                    dropzoneText="Arraste los archivos aquí o haga click para seleccionar"
                    showAlerts={false}
                    filesLimit={10}
                    maxFileSize={15000000}
                    onDropRejected={handleOnDocRejected}
                  />
                </CardContent>
              </Card>
              ) : null}
              <Card className={styles.marginTop}>
                {reportFile.length > 0 ? (
                  <List subheader={<ListSubheader>Archivos</ListSubheader>}>
                    {reportFile.map((file, i) => {
                      return <ReportFileItem file={file} fileDeleted={loadSelectedReport}/>;
                    })}
                  </List>
                ) : null}
              </Card>
            </DialogContent>
            {logedUser.u_role_id !== 3 ? (
              <DialogActions>
                {!report.archived ? (
                  <Button onClick={handleArchive} style={{ color: '#e91e63' }}>
                    Archivar
                  </Button>
                ) : null}
                <div style={{ flex: '1 0 0' }} />
                <Button onClick={handleDelete} style={{ color: 'red' }}>
                  Eliminar
                </Button>
                {!report.archived ? (
                  <Button disabled={!modified} type="submit" color="primary">
                    Guardar
                  </Button>
                ) : null}
              </DialogActions>
            ) : null}
          </form>
          <ConfirmDialog
            handleClose={handleCloseConfirmDeleteDialog}
            handleOnConfirm={handleOnConfirmDelete}
            visibility={confirmDeleteDialogVisibility}
            title="¿Está seguro que desea eliminar este informe?"
            message="La eliminación de este informe es permanente"
          />
          <ConfirmDialog
            handleClose={handleCloseConfirmArchiveDialog}
            handleOnConfirm={handleOnConfirmArchive}
            visibility={confirmArchiveDialogVisibility}
            title="¿Está seguro que desea archivar este informe?"
            message="Podrás visualizarlo en la pantalla Historial"
          />
        </Modal>
        <Backdrop className={styles.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ReportModal;
