import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Users as UsersView,
  Clients as ClientsView,
  Jobs as JobsView,
  JobsHistory as JobsHistoryView,
  ReportsHistory as ReportsHistoryView,
  Reports as ReportsView,
  EquipmentsCertificates as EquipmentsCertificatesView,
  Schedules as SchedulesView,
  Configurations as ConfigurationsView,
  SignIn as SignInView,
  ConfirmPassword as ConfirmPasswordView,
  ConfirmEmail as ConfirmEmailView,
  ResetPassword as ResetPasswordView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/jobs" />
      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />
      <RouteWithLayout component={UsersView} exact layout={MainLayout} path="/users" />
      <RouteWithLayout component={ClientsView} exact layout={MainLayout} path="/clients" />
      <RouteWithLayout component={JobsView} exact layout={MainLayout} path="/jobs" />
      <RouteWithLayout component={JobsHistoryView} exact layout={MainLayout} path="/jobs-history" />
      <RouteWithLayout component={ReportsHistoryView} exact layout={MainLayout} path="/reports-history" />
      <RouteWithLayout component={ReportsView} exact layout={MainLayout} path="/reports" />
      <RouteWithLayout component={EquipmentsCertificatesView} exact layout={MainLayout} path="/equipments_certificates" />
      <RouteWithLayout component={SchedulesView} exact layout={MainLayout} path="/schedules" />
      <RouteWithLayout component={ConfigurationsView} exact layout={MainLayout} path="/configurations" />
      <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/sign-in" />
      <RouteWithLayout component={ConfirmPasswordView} exact layout={MinimalLayout} path="/confirm-password" />
      <RouteWithLayout component={ConfirmEmailView} exact layout={MinimalLayout} path="/confirm-email" />
      <RouteWithLayout component={ResetPasswordView} exact layout={MinimalLayout} path="/reset-password" />
      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
