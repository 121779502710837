import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import JWTDecoder from 'jwt-decode';
import { Grid, Typography } from '@material-ui/core';

import Api from '../../Api';
import ReportModal from './components/ReportModal';
import NewReportModal from './components/NewReportModal';
import GenericToolbar from '../../components/GenericToolbar/GenericToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Reports = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        display: false
      }
    },
    {
      name: 'client.name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'plant.name',
      label: 'Planta',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'identifier',
      label: 'Identificador',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'year',
      label: 'Año',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'month',
      label: 'Mes',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'quotation_identifier',
      label: 'Nro. Cotización',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'sector.name',
      label: 'Sector',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'matrix.name',
      label: 'Matriz',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'item.name',
      label: 'Servicio / Trabajo',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'created_on_formatted',
      label: 'Fecha',
      options: {
        filter: false,
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    onRowClick: row => {
      handleClickOpen(row);
    },
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [visibility, setVisibility] = useState(false);
  const [visibilityNew, setVisibilityNew] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [selectedReportFiles, setSelectedReportFiles] = useState({});

  const handleClickOpen = row => {
    reports.forEach(report => {
      if (report.id === row[0]) {
        setSelectedReport(report);
        setSelectedReportFiles(report.files);
      }
    });
    setVisibility(true);
  };

  const handleClickOpenNew = () => {
    setVisibilityNew(true);
  };

  const handleCloseNew = success => {
    if (success) {
      loadReports();
    }
    setVisibilityNew(false);
  };

  const handleClose = success => {
    if (success) {
      loadReports();
    }
    setVisibility(false);
  };

  const [reports, setReports] = useState([]);
  const [clients, setClients] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [matrices, setMatrices] = useState([]);
  const [items, setItems] = useState([]);

  const loadReports = () => {
    Api.get('/reports?archived=false')
      .then(function(response) {
        response.data.data.forEach(r => {
          r.created_on_formatted = moment(r.created_on).format('DD/MM/YYYY');
        });
        setReports(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setReports([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  };

  const loadSelectedReport = () => {
    Api.get('/reports/' + selectedReport.id)
      .then(function(response) {
        setSelectedReport(response.data.data);
        setSelectedReportFiles(response.data.data.files);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';

        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });

      });
  };

  useEffect(() => {
    loadReports();
    Api.get('/clients')
      .then(function(response) {
        setClients(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setClients([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/sectors')
      .then(function(response) {
        setSectors(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setSectors([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/matrices')
      .then(function(response) {
        setMatrices(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setMatrices([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
    Api.get('/items')
      .then(function(response) {
        setItems(response.data.data);
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          setItems([]);
        } else {
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'error'
          });
        }
      });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={9}>
            <Typography style={{color:"black", height:"18px", paddingTop:"22px", fontWeight: 600, textTransform: "uppercase", fontFamily: ['"Roboto"']}}>Los informes se mantendrán en la plataforma por un lapso de 2 años</Typography>
        </Grid>
        {jwtUser.u_role_id !== 3 ? <Grid item xs={3}><GenericToolbar handleClickOpenNew={handleClickOpenNew} /></Grid> : null}
      </Grid>
      <div className={classes.content}>
        <MUIDataTable title={'Lista de Informes'} data={reports} columns={columns} options={options} />
      </div>
      <ReportModal handleClose={handleClose} logedUser={jwtUser} visibility={visibility} report={selectedReport} reportFiles={selectedReportFiles} loadSelectedReport={loadSelectedReport}/>
      <NewReportModal handleClose={handleCloseNew} visibility={visibilityNew} clients={clients} sectors={sectors} matrices={matrices} items={items} />
      
    </div>
  );
};

export default Reports;
