import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';

import Api from '../../../Api';
import PlantModal from './PlantModal';
import NewPlantModal from './NewPlantModal';
import Modal from '../../../components/Modal/Modal';
import GenericToolbar from '../../../components/GenericToolbar/GenericToolbar';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ClientModal = ({ handleClose, onSuccess, loadSelectedClient, loadCities, visibility, client, states, cities }) => {
  const { enqueueSnackbar } = useSnackbar();
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'state',
      label: 'Provincia',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'city',
      label: 'Localidad',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'address',
      label: 'Direccion',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'contact',
      label: 'Contacto',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'phone',
      label: 'Telefono',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    onRowClick: row => {
      handleClickOpenPlant(row);
    },
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [visibilityNew, setVisibilityNew] = useState(false);
  const [visibilityPlant, setVisibilityPlant] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState({});
  const [name, setName] = useState(client.name);
  const [document, setDocument] = useState(client.document);
  const [email, setEmail] = useState(client.email);
  const [phone, setPhone] = useState(client.phone);
  const [state, setState] = useState(client.state);
  const [city, setCity] = useState(client.city);
  const [address, setAddress] = useState(client.address);

  useEffect(() => {
    setName(client.name);
    setDocument(client.document);
    setEmail(client.email)
    setPhone(client.phone)
    setState(client.state)
    setCity(client.city)
    setAddress(client.address)
  }, [client]);

  const handleClickOpenNew = () => {
    setVisibilityNew(true);
  };

  const handleClickOpenPlant = row => {
    client.plants.forEach(plant => {
      if (plant.id === row[0]) {
        setSelectedPlant(plant);
      }
    });
    setVisibilityPlant(true);
  };

  const handleCloseNew = success => {
    if (success) {
      loadSelectedClient();
    }
    setVisibilityNew(false);
  };
  const handleClosePlant = () => {
    setVisibilityPlant(false);
  };

  const handleSaveClient = async event => {
    event.preventDefault();
    Api.put('/clients/' + client.id, {
      name: name,
      document: document,
      email: email,
      phone: phone,
      address: address
    })
      .then(function(response) {
        handleClose();
        onSuccess();
        enqueueSnackbar('¡Estado modificado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const handleChangeName = (event, name) => {
    if (event.target.value === '') {
      setName(null);
    } else {
      setName(event.target.value);
    }
  };
  const handleChangeDocument = (event, name) => {
    if (event.target.value === '') {
      setDocument(null);
    } else {
      setDocument(event.target.value);
    }
  };
  const handleChangeEmail = (event, name) => {
    if (event.target.value === '') {
      setEmail(null);
    } else {
      setEmail(event.target.value);
    }
  };
  const handleChangePhone = (event, name) => {
    if (event.target.value === '') {
      setPhone(null);
    } else {
      setPhone(event.target.value);
    }
  };
  const handleChangeState = (event, name) => {
    if (event.target.value === '') {
      setState(null);
    } else {
      setState(event.target.value);
    }
  };
  const handleChangeCity = (event, name) => {
    if (event.target.value === '') {
      setCity(null);
    } else {
      setCity(event.target.value);
    }
  };
  const handleChangeAddress = (event, name) => {
    if (event.target.value === '') {
      setAddress(null);
    } else {
      setAddress(event.target.value);
    }
  };

  return (
    <div>
      <Modal handleClose={handleClose} visibility={visibility} title="Cliente" maxWidth="lg">
      <form onSubmit={handleSaveClient}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth label="Nombre" name="name" type="text" value={name || ''} onChange={handleChangeName} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="CUIT" name="document" type="text" value={document || ''} onChange={handleChangeDocument} variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth label="E-Mail" name="email" type="text" value={email || ''} onChange={handleChangeEmail} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Número de teléfono" name="phone" type="text" value={phone || ''} onChange={handleChangePhone} variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth disabled label="Provincia" name="state" type="text" value={state || ''} onChange={handleChangeState} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth disabled label="Localidad" name="city" type="text" value={city || ''} onChange={handleChangeCity} variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth label="Dirección" name="address" type="text" value={address || ''} onChange={handleChangeAddress} variant="outlined" />
            </Grid>
          </Grid>
          <Card style={{ marginTop: '15px' }}>
            <CardContent>
              <GenericToolbar handleClickOpenNew={handleClickOpenNew} newButtonText={'Nueva Planta'} />
              <div style={{ marginTop: '15px' }}>
                <MUIDataTable title={'Plantas'} data={client.plants} columns={columns} options={options} />
              </div>
              <PlantModal
                handleClose={handleClosePlant}
                onSuccess={loadSelectedClient}
                visibility={visibilityPlant}
                plant={selectedPlant}
              />
              <NewPlantModal
                handleClose={handleCloseNew}
                loadCities={loadCities}
                visibility={visibilityNew}
                states={states}
                cities={cities}
                clientId={client.id}
              />
            </CardContent>
          </Card>
          <DialogActions>
            <Button type="submit" color="primary">
              Guardar
            </Button>
          </DialogActions>
        </DialogContent>
        </form>
      </Modal>
    </div>
  );
};

export default ClientModal;
