import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import validate from 'validate.js';

import Modal from '../../../components/Modal/Modal';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { monthsShort } from 'moment';

const schema = {
  frequency: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  objective: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  progress: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  sectors: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  matrices: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  },
  items: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const ScheduleDetailModal = ({ handleClose, visibility, addNewDetailModal, sectors, matrices, items, startDate, endDate }) => {
  const styles = useStyles();

  const [years, setYears] = useState([
    { id: 0, name: (parseInt(moment().format('YYYY')) - 1).toString() },
    { id: 1, name: moment().format('YYYY') },
    { id: 2, name: (parseInt(moment().format('YYYY')) + 1).toString() },
    { id: 3, name: (parseInt(moment().format('YYYY')) + 2).toString() }
  ]);

  const [months, setMonths] = useState([
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' }
  ]);

  const [frequencies, setFrequencies] = useState([
    { id: 1, name: 'Anual' },
    { id: 2, name: 'Semetral' },
    { id: 3, name: 'Cuatrimestral' },
    { id: 4, name: 'Trimestral' },
    { id: 5, name: 'Bimestral' },
    { id: 6, name: 'Mensual' },
    { id: 7, name: 'Quincenal' },
    { id: 8, name: 'Semanal' }
  ]);

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [confirmDismissDialogVisibility, setConfirmDismissDialogVisibility] = useState(false);

  useEffect(() => {
    if (close) {
      setClose(false);
      setLoading(false);
      handleClose(true);
    }
    if (startDate instanceof moment) {
      setStartDateMonth(startDate.format('M') - 1);
      setSelectedMonth(months[startDate.format('M') - 1]);
      years.forEach(year => {
        if (year.name == startDate.format('Y')) {
          setSelectedYear(year);
        }
      });
    }
    if (endDate instanceof moment) {
      setEndDateMonth(endDate.format('M'));
    }
  }, [close, startDate, endDate]);

  const [selectedSector, setSelectedSector] = useState();
  const handleChangeSector = sectorId => {
    sectors.forEach(sector => {
      if (sector.id == sectorId) {
        setSelectedSector(sector);
      }
    });
  };

  const [selectedMatrix, setSelectedMatrix] = useState();
  const handleChangeMatrix = matrixId => {
    matrices.forEach(matrix => {
      if (matrix.id == matrixId) {
        setSelectedMatrix(matrix);
      }
    });
  };

  const [selectedItem, setSelectedItem] = useState();
  const handleChangeItem = itemId => {
    items.forEach(item => {
      if (item.id == itemId) {
        setSelectedItem(item);
      }
    });
  };
  const [selectedFrequency, setSelectedFrequency] = useState();
  const handleChangeFrequency = frequencyId => {
    frequencies.forEach(frequency => {
      if (frequency.id === frequencyId) {
        setSelectedFrequency(frequency);
      }
    });
  };

  const [startDateMonth, setStartDateMonth] = useState(0);
  const [endDateMonth, setEndDateMonth] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const handleChangeMonth = monthId => {
    months.forEach(month => {
      if (month.id === monthId) {
        setSelectedMonth(month);
      }
    });
  };
  const [selectedYear, setSelectedYear] = useState(years[1]);
  const handleChangeYear = yearId => {
    years.forEach(year => {
      if (year.id === yearId) {
        setSelectedYear(year);
      }
    });
  };

  const handleCloseConfirmDismissDialog = () => {
    setConfirmDismissDialogVisibility(false);
  };

  const handleCloseDismiss = () => {
    handleClose();
  };

  const handleOnConfirmDismiss = () => {
    setConfirmDismissDialogVisibility(false);
    handleClose();
  };

  const handleAddScheduleDetail = async event => {
    event.preventDefault();
    setLoading(true);

    var newDetails = [];
    var curYear = selectedYear;
    var curMonth = selectedMonth;
    var curMonthId = selectedMonth.id;
    var i = 0;
    switch (selectedFrequency.id) {
      case 1:
        var newDetail = {
          year: curYear.name,
          month: curMonth,
          sector: selectedSector,
          matrix: selectedMatrix,
          item: selectedItem,
          objective: formState.values.objective,
          progress: formState.values.progress,
          comment: formState.values.comments
        };
        newDetails.push(newDetail);
        break;
      case 2:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions / 6);
        if (repetitions > 2) repetitions = 2;
        while (i < repetitions) {
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: formState.values.comments
          };
          newDetails.push(newDetail);
          curMonthId = curMonth.id + 6;
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      case 3:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions / 4);
        if (repetitions > 3) repetitions = 3;
        while (i < repetitions) {
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: formState.values.comments
          };
          newDetails.push(newDetail);
          curMonthId = curMonth.id + 4;
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      case 4:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions / 3);
        if (repetitions > 4) repetitions = 4;
        while (i < repetitions) {
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: formState.values.comments
          };
          newDetails.push(newDetail);
          curMonthId = curMonth.id + 3;
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      case 5:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions / 2);
        if (repetitions > 6) repetitions = 6;
        while (i < repetitions) {
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: formState.values.comments
          };
          newDetails.push(newDetail);
          curMonthId = curMonth.id + 2;
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      case 6:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions);
        if (repetitions > 12) repetitions = 12;
        while (i < repetitions) {
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: formState.values.comments
          };
          newDetails.push(newDetail);
          curMonthId = curMonth.id + 1;
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      case 7:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions * 2);
        if (repetitions > 24) repetitions = 24;
        while (i < repetitions) {
          var comment = '';
          if (i % 2 == 0) {
            comment = 'Primera Quincena';
          } else {
            comment = 'Segunda Quincena';
          }
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: comment
          };
          newDetails.push(newDetail);
          if (i % 2 != 0) {
            curMonthId = curMonth.id + 1;
          }
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      case 8:
        var repetitions = 0;
        if (endDateMonth <= selectedMonth.id) {
          repetitions = parseInt(endDateMonth) + 13 - selectedMonth.id;
        } else {
          repetitions = parseInt(endDateMonth) + 1 - selectedMonth.id;
        }
        repetitions = Math.ceil(repetitions * 4);
        if (repetitions > 48) repetitions = 48;
        while (i < repetitions) {
          var comment = '';
          if (i % 4 == 0) {
            comment = 'Primera Semana';
          } else {
            if (i % 4 == 1) {
              comment = 'Segunda Semana';
            } else {
              if (i % 4 == 2) {
                comment = 'Tercer Semana';
              } else {
                comment = 'Cuarta Semana';
              }
            }
          }
          var newDetail = {
            year: curYear.name,
            month: curMonth,
            sector: selectedSector,
            matrix: selectedMatrix,
            item: selectedItem,
            objective: formState.values.objective,
            progress: formState.values.progress,
            comment: comment
          };
          newDetails.push(newDetail);
          if (i % 4 == 3) {
            curMonthId = curMonth.id + 1;
          }
          if (curMonthId > 12) {
            curMonthId = curMonthId - 12;
            var selectedYearId = curYear.id + 1;
            years.forEach(year => {
              if (year.id === selectedYearId) {
                curYear = year;
              }
            });
          }
          months.forEach(month => {
            if (month.id === curMonthId) {
              curMonth = month;
            }
          });
          i = i + 1;
        }
        break;
      default:
        break;
    }
    addNewDetailModal(newDetails);

    formState.values.objective = '';
    formState.values.progress = '';
    formState.values.comments = '';

    setClose(true);
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nueva Tarea" maxWidth="md">
        <form onSubmit={handleAddScheduleDetail}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Autocomplete
                  className={styles.marginTop}
                  name="year"
                  options={years}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeYear(value.id);
                    handleChange(option, 'years');
                  }}
                  defaultValue={selectedYear}
                  renderInput={params => <TextField {...params} variant="outlined" label="Año" />}
                  error={hasError('year')}
                  helperText={hasError('year') ? formState.errors.year[0] : null}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  className={styles.marginTop}
                  name="month"
                  options={months}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeMonth(value.id);
                    handleChange(option, 'months');
                  }}
                  defaultValue={selectedMonth}
                  renderInput={params => <TextField {...params} variant="outlined" label="Mes" />}
                  error={hasError('month')}
                  helperText={hasError('month') ? formState.errors.month[0] : null}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  className={styles.marginTop}
                  name="frequency"
                  options={frequencies}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeFrequency(value.id);
                    handleChange(option, 'frequency');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Frecuencia" />}
                  error={hasError('frequency')}
                  helperText={hasError('frequency') ? formState.errors.frequency[0] : null}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Autocomplete
                  name="sectors"
                  options={sectors}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeSector(value.id);
                    handleChange(option, 'sectors');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Sector" />}
                  error={hasError('sectors')}
                  helperText={hasError('sectors') ? formState.errors.sectors[0] : null}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={selectedSector == null ? 'disabled' : null}
                  name="matrices"
                  options={selectedSector == null ? matrices : selectedSector.matrices}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeMatrix(value.id);
                    handleChange(option, 'matrices');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Matriz" />}
                  error={hasError('matrices')}
                  helperText={hasError('matrices') ? formState.errors.matrices[0] : null}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={selectedMatrix == null ? 'disabled' : null}
                  name="items"
                  options={selectedMatrix == null ? items : selectedMatrix.items}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeItem(value.id);
                    handleChange(option, 'items');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Servicio / Trabajo" />}
                  error={hasError('items')}
                  helperText={hasError('items') ? formState.errors.items[0] : null}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  className={styles.marginTop}
                  fullWidth
                  error={hasError('objective')}
                  helperText={hasError('objective') ? formState.errors.objective[0] : null}
                  label="Total"
                  name="objective"
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  value={formState.values.objective || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={styles.marginTop}
                  fullWidth
                  error={hasError('progress')}
                  helperText={hasError('progress') ? formState.errors.progress[0] : null}
                  label="Parcial"
                  name="progress"
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  value={formState.values.progress || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <TextField
              className={styles.marginTop}
              multiline
              fullWidth
              label="Comentarios"
              name="comments"
              type="text"
              onChange={handleChange}
              value={formState.values.comments || ''}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
        <ConfirmDialog
          handleClose={handleCloseConfirmDismissDialog}
          handleOnConfirm={handleOnConfirmDismiss}
          visibility={confirmDismissDialogVisibility}
          title="¿Está seguro que desea salir?"
          message="Hay cambios sin guardar"
        />
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ScheduleDetailModal;
