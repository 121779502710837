import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.grey[50]
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 80,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto'
    }
  },
  content: {
    padding: theme.spacing(1, 2)
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Info = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.media}>
        <img alt="Upgrade to PRO" src="/images/logos/logo.svg" />
      </div>
      <div className={classes.content}>
        <Typography align="center" gutterBottom variant="h6">
          Industria y Ambiente
        </Typography>
        <Typography align="center" variant="body2" gutterBottom>
          Merlo 10, La Tablada, Buenos Aires, Argentina
        </Typography>
        <Typography align="center" variant="body2">
          +54 11 4454-3474
        </Typography>
        <Typography align="center" variant="body2">
          +54 11 4453-2436
        </Typography>
        <Typography align="center" variant="body2">
          +54 11 4454-4859
        </Typography>
      </div>
    </div>
  );
};

Info.propTypes = {
  className: PropTypes.string
};

export default Info;
