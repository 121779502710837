import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import { DropzoneArea } from 'material-ui-dropzone';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

moment.locale('es');

const schema = {
  equipment_name: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 4,
      maximum: 64,
      message: '^Debe contener al menos 4 caracteres'
    }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const NewEquipmentCertificateModal = ({ handleClose, visibility }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [years, setYears] = useState([
    { id: 0, name: (parseInt(moment().format('YYYY')) - 1).toString() },
    { id: 1, name: moment().format('YYYY') },
    { id: 2, name: (parseInt(moment().format('YYYY')) + 1).toString() }
  ]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [selectedYear, setSelectedYear] = useState(years[1]);
  const handleChangeYear = yearId => {
    years.forEach(year => {
      if (year.id === yearId) {
        setSelectedYear(year);
      }
    });
  };

  const [files, setFiles] = useState([]);
  const handleChangeFiles = files => {
    setFiles(files);
  };

  const handleOnDocRejected = () => {
    enqueueSnackbar('Archivo no válido! Recordá que debe tener un tamaño máximo de 10mb.', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      variant: 'error'
    });
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [addingFile, setAddingFile] = useState(0);
  useEffect(() => {
    if (close && addingFile < 1) {
      setClose(false);
      setLoading(false);
      handleClose(true);
    }
  }, [close, addingFile]);

  const handleCloseDismiss = () => {
    handleClose();
  };

  const handleCreateEquipmentCertificate = async event => {
    event.preventDefault();
    setLoading(true);
    Api.post('/equipments_certificates', {
      equipment_name: formState.values.equipment_name,
      certificate_year: selectedYear.name
    })
      .then(function(response) {
        setSelectedYear(years[1]);
        setAddingFile(files.length);
        files.forEach(async file => {
          await createEquipmentCertificateFiles(response.data.data.id, file);
        });
        setClose(true);
        formState.values.equipment_name = '';
        formState.values.certificate_year = '';
        enqueueSnackbar('Certificación de Equipo creada exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const createEquipmentCertificateFiles = async (equipmentCertificateId, file) => {
    const data = new FormData();
    data.append('file', file);
    Api.post('/files/upload', data)
      .then(function(response) {
        Api.post('/equipments_certificates_files', {
          equipment_certificate_id: equipmentCertificateId,
          name: file.name,
          url: response.data.fileUrl
        });
        setAddingFile(addingFile - 1);
      })
      .catch(function() {
        setAddingFile(addingFile - 1);
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nuevo Certificado de Equipo">
        <form onSubmit={handleCreateEquipmentCertificate}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  error={hasError('equipment_name')}
                  helperText={hasError('equipment_name') ? formState.errors.equipment_name[0] : null}
                  label="Equipo"
                  name="equipment_name"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.equipment_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  name="certificate_year"
                  options={years}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeYear(value.id);
                    handleChange(option, 'certificate_year');
                  }}
                  defaultValue={years[1]}
                  renderInput={params => <TextField {...params} variant="outlined" label="Año" />}
                />
              </Grid>
            </Grid>
            <Card className={styles.marginTop}>
              <CardContent>
                <DropzoneArea
                  dropzoneClass={styles.dropzone}
                  acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/msword']}
                  onChange={handleChangeFiles}
                  showFileNames
                  dropzoneText="Arraste los archivos aquí o haga click para seleccionar"
                  showAlerts={false}
                  filesLimit={10}
                  maxFileSize={10000000}
                  onDropRejected={handleOnDocRejected}
                />
              </CardContent>
            </Card>
          </DialogContent>

          <DialogActions>
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default NewEquipmentCertificateModal;
