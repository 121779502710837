import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const ScheduleDetailModal = ({ handleClose, onSuccess, logedUser, visibility, scheduleDetail, loadSelectedSchedule }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [updatingSchedule, setUpdatingSchedule] = useState(false);
  const [newProgress, setNewProgress] = useState({});
  const [newComment, setNewComment] = useState({});
  const [modified, setModified] = useState(false);
  const [confirmDeleteDialogVisibility, setConfirmDeleteDialogVisibility] = useState(false);
  const [confirmDismissDialogVisibility, setConfirmDismissDialogVisibility] = useState(false);

  useEffect(() => {
    setNewProgress(scheduleDetail.progress);
    setNewComment(scheduleDetail.comment);
  }, [scheduleDetail]);

  useEffect(() => {
    if (close && !updatingSchedule) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, updatingSchedule]);

  useEffect(() => {
    if (newProgress === scheduleDetail.progress && newComment === scheduleDetail.comment) {
      setModified(false);
    } else {
      setModified(true);
    }
  }, [newProgress, newComment]);

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisibility(false);
  };

  const handleDelete = () => {
    setConfirmDeleteDialogVisibility(true);
  };

  const handleOnConfirmDelete = () => {
    setConfirmDeleteDialogVisibility(false);
    handleDeleteScheduleDetail();
  };

  const handleCloseConfirmDismissDialog = () => {
    setConfirmDismissDialogVisibility(false);
  };

  const handleCloseDismiss = () => {
    if (modified) {
      setConfirmDismissDialogVisibility(true);
    } else {
      handleClose();
    }
  };

  const handleOnConfirmDismiss = () => {
    setConfirmDismissDialogVisibility(false);
    handleClose();
  };

  const handleChangeProgress = (event, name) => {
    if (event.target.value === '') {
      setNewProgress(null);
    } else {
      setNewProgress(event.target.value);
    }
  };

  const handleChangeComment = (event, name) => {
    if (event.target.value === '') {
      setNewComment(null);
    } else {
      setNewComment(event.target.value);
    }
  };

  const handleSaveScheduleDetail = async event => {
    event.preventDefault();
    setLoading(true);

    if (newProgress !== scheduleDetail.progress || newComment !== scheduleDetail.comment) {
      setUpdatingSchedule(true);
      updateScheduleProgress(scheduleDetail.id);
    }
    setClose(true);
  };

  const handleDeleteScheduleDetail = () => {
    setLoading(true);

    deleteScheduleDetail(scheduleDetail.id);
    setClose(true);
  };

  const deleteScheduleDetail = async scheduleId => {
    Api.delete('/schedules_details/' + scheduleId)
      .then(function() {
        loadSelectedSchedule()
        setLoading(false);
        setClose(true);
        enqueueSnackbar('Cronograma eliminado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function() {
        return false;
      });
  };

  const updateScheduleProgress = async scheduleDetailId => {
    var data = { progress: parseInt(newProgress) };
    if (newComment) {
      data['comment'] = newComment;
    }

    Api.put('/schedules_details/' + scheduleDetailId, data)
      .then(function(response) {
        setUpdatingSchedule(false);
        enqueueSnackbar('Detalle de Cronograma actualizado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
        setModified(false);
        onSuccess();
      })
      .catch(function(error) {
        setUpdatingSchedule(false);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Detalle de Cronograma" maxWidth="md">
        
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  className={styles.marginTop}
                  multiline
                  disabled
                  fullWidth
                  label="Año"
                  name="year"
                  type="text"
                  value={scheduleDetail.year || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={styles.marginTop}
                  multiline
                  disabled
                  fullWidth
                  label="Mes"
                  name="month"
                  type="text"
                  value={scheduleDetail.month || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={styles.marginTop}
                  multiline
                  disabled
                  fullWidth
                  label="Matriz"
                  name="matrixName"
                  type="text"
                  value={scheduleDetail.matrix !== undefined ? scheduleDetail.matrix.name : ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  className={styles.marginTop}
                  multiline
                  disabled
                  fullWidth
                  label="Total"
                  name="objetive"
                  type="text"
                  value={scheduleDetail.objective || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={styles.marginTop}
                  multiline
                  disabled={logedUser.u_role_id === 3 ? true : false}
                  fullWidth
                  label="Parcial"
                  name="progress"
                  type="text"
                  value={newProgress || ''}
                  onChange={handleChangeProgress}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <TextField
              className={styles.marginTop}
              multiline
              disabled={logedUser.u_role_id === 3 ? true : false}
              fullWidth
              label="Comentarios"
              name="comments"
              type="text"
              onChange={handleChangeComment}
              value={newComment || ''}
              variant="outlined"
            />
          </DialogContent>
          {logedUser.u_role_id !== 3 ? (
            <DialogActions>
              <div style={{ flex: '1 0 0' }} />
              <Button onClick={handleDelete} style={{ color: 'red' }}>
                Eliminar
              </Button>
              <Button disabled={!modified} onClick={handleSaveScheduleDetail} color="primary">
                Guardar
              </Button>
            </DialogActions>
          ) : null}
        <ConfirmDialog
          handleClose={handleCloseConfirmDeleteDialog}
          handleOnConfirm={handleOnConfirmDelete}
          visibility={confirmDeleteDialogVisibility}
          title="¿Está seguro que desea eliminar este cronograma?"
          message="La eliminación de este cronograma es permanente"
        />
        <ConfirmDialog
          handleClose={handleCloseConfirmDismissDialog}
          handleOnConfirm={handleOnConfirmDismiss}
          visibility={confirmDismissDialogVisibility}
          title="¿Está seguro que desea salir?"
          message="Hay cambios sin guardar"
        />
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ScheduleDetailModal;
