import React, { useState, useEffect, Controller } from 'react';
import { Grid, Button, TextField, Card, CardContent, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import 'moment/locale/es';
import momentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';
import JobFileItem from './JobFileItem';

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    height: '40%',
    minHeight: '100px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const JobModal = ({ handleClose, handleFileToDelete, logedUser, visibility, job, jobFiles, users, statuses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortDirection: 'desc'
      }
    },
    {
      name: 'job_status_before.name',
      label: 'Estado Previo',
      options: {
        sort: true
      }
    },
    {
      name: 'job_status_after.name',
      label: 'Estado Asignado',
      options: {
        sort: true
      }
    },
    {
      name: 'user.fullname',
      label: 'Usuario',
      options: {
        sort: true
      }
    },
    {
      name: 'created_on_formatted',
      label: 'Fecha y Hora',
      options: {
        sort: true
      }
    }
  ];
  const options = {
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    filter: false,
    responsive: 'standard',
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: 'No hay resultados por ahora',
        toolTip: 'Order',
        columnHeaderTooltip: column => `Ordernar por ${column.label}`
      },
      pagination: {
        next: 'Pagina siguiente',
        previous: 'Pagina anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'BORRAR FILTROS'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas'
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [updatingJob, setUpdatingJob] = useState(false);
  const [creatingJobHistory, setCreatingJobHistory] = useState(false);
  const [deletingJobHistory, setDeletingJobHistory] = useState(0);
  const [deletingJobNotification, setDeletingJobNotification] = useState(0);
  const [addingUser, setAddingUser] = useState(0);
  const [deletingUser, setDeletingUser] = useState(0);
  const [addingFile, setAddingFile] = useState(0);
  const [deletingFile, setDeletingFile] = useState(0);
  const [newStatusId, setNewStatusId] = useState({});
  const [modified, setModified] = useState(false);
  const [selectedExtensionDate, setSelectedExtensionDate] = useState();
  const [addUsers, setAddUsers] = useState([]);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const [addFiles, setAddFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [comments, setComments] = useState(job.comments);
  const [confirmDeleteDialogVisibility, setConfirmDeleteDialogVisibility] = useState(false);
  const [confirmDismissDialogVisibility, setConfirmDismissDialogVisibility] = useState(false);
  const [confirmArchiveDialogVisibility, setConfirmArchiveDialogVisibility] = useState(false);

  useEffect(() => {
    setNewStatusId(job.job_status_id);
    setComments(job.comments);
    setAddUsers([]);
    setDeleteUsers([]);
    setAddFiles([]);
    setDeleteFiles([]);
    setSelectedExtensionDate();
  }, [job]);

  useEffect(() => {
    if (close && !updatingJob && !creatingJobHistory && addingUser < 1 && deletingUser < 1 && addingFile < 1 && deletingFile < 1) {
      setLoading(false);
      handleClose(true);
    }
  }, [close, updatingJob, creatingJobHistory, addingUser, deletingUser, addingFile, deletingFile, deletingJobHistory, deletingJobNotification]);

  useEffect(() => {
    if (
      comments === job.comments &&
      newStatusId === job.job_status_id &&
      addUsers.length === 0 &&
      deleteUsers.length === 0 &&
      addFiles.length === 0 &&
      deleteFiles.length === 0 &&
      !selectedExtensionDate
    ) {
      setModified(false);
    } else {
      setModified(true);
    }
  }, [newStatusId, addUsers, deleteUsers, addFiles, deleteFiles, selectedExtensionDate, comments]);

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisibility(false);
  };

  const handleDelete = () => {
    setConfirmDeleteDialogVisibility(true);
  };

  const handleOnConfirmDelete = () => {
    setConfirmDeleteDialogVisibility(false);
    handleDeleteJob();
  };

  const handleCloseConfirmArchiveDialog = () => {
    setConfirmArchiveDialogVisibility(false);
  };

  const handleArchive = () => {
    setConfirmArchiveDialogVisibility(true);
  };

  const handleOnConfirmArchive = () => {
    setConfirmArchiveDialogVisibility(false);
    handleArchiveJob();
  };

  const handleCloseConfirmDismissDialog = () => {
    setConfirmDismissDialogVisibility(false);
  };

  const handleCloseDismiss = () => {
    if (modified) {
      setConfirmDismissDialogVisibility(true);
    } else {
      handleClose();
    }
  };

  const handleOnConfirmDismiss = () => {
    setConfirmDismissDialogVisibility(false);
    handleClose();
  };

  const handleChangeStatus = selectedStatusId => {
    setNewStatusId(selectedStatusId);
  };
  const handleChangeUsersToNotify = selectedUsers => {
    const addUsers = selectedUsers.filter(elem => !job.users.find(({ id }) => elem.id === id));
    setAddUsers(addUsers);

    const deleteUsers = job.users.filter(elem => !selectedUsers.find(({ id }) => elem.id === id));
    setDeleteUsers(deleteUsers);
  };

  const handleExtensionDateChange = date => {
    setSelectedExtensionDate(date);
  };

  const handleChangeFiles = files => {
    setAddFiles(files);
  };

  const handleChangeComments = (event, name) => {
    if (event.target.value === '') {
      setComments(null);
    } else {
      setComments(event.target.value);
    }
  };

  const handleConfirmDeleteJobFile = file => {
    setDeleteFiles(deleteFiles => [...deleteFiles, file]);
    handleFileToDelete(file);
  };

  const handleOnDocRejected = () => {
    enqueueSnackbar('Archivo no válido! Recordá que debe tener un tamaño máximo de 10mb.', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      variant: 'error'
    });
  };

  const handleSaveJob = async event => {
    event.preventDefault();
    setLoading(true);

    if (newStatusId !== job.job_status_id || comments !== job.comments || selectedExtensionDate) {
      setUpdatingJob(true);
      updateJobStatus(job.id, newStatusId);
      if (newStatusId !== job.job_status_id) {
        setCreatingJobHistory(true);
        createJobHistory(job, logedUser.u_id, newStatusId);
      }
    }

    setAddingUser(addUsers.length);
    for (const user of addUsers) {
      await createJobUsers(job.id, user);
    }
    setDeletingUser(deleteUsers.length);
    for (const user of deleteUsers) {
      await deleteJobUsers(job.id, user);
    }
    setDeletingFile(deleteFiles.length);
    for (const file of deleteFiles) {
      await deleteJobFiles(file);
    }
    setAddingFile(addFiles.length);
    for (const file of addFiles) {
      await createJobFiles(job.id, file);
    }
    setClose(true);
  };

  const handleDeleteJob = () => {
    setLoading(true);

    if (job.histories !== undefined) {
      setDeletingJobHistory(job.histories.length);
      job.histories.forEach(async history => {
        await deleteJobHistory(history);
      });
    }
    if (job.notifications !== undefined) {
      setDeletingJobNotification(job.notifications.length);
      job.notifications.forEach(async notification => {
        await deleteJobNotification(notification);
      });
    }
    if (job.files !== undefined) {
      setDeletingFile(job.files.length);
      job.files.forEach(async file => {
        await deleteJobFiles(file, true);
      });
    }
    if (job.users !== undefined) {
      setDeletingUser(job.users.length);
      job.users.forEach(async user => {
        await deleteJobUsers(job.id, user, true);
      });
    }
    deleteJob(job.id);
    setClose(true);
  };

  const createJobUsers = async (jobId, user) => {
    Api.post('/jobs_users', {
      job_id: jobId,
      user_id: user.id
    })
      .then(function() {
        setAddingUser(addingUser - 1);
        enqueueSnackbar('Usuario "' + user.firstname + ' ' + user.lastname + '" agregado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function() {
        setAddingUser(addingUser - 1);
        return false;
      });
  };

  const deleteJob = async jobId => {
    Api.delete('/jobs/' + jobId)
      .then(function() {
        enqueueSnackbar('Vencimiento eliminado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function() {
        return false;
      });
  };

  const deleteJobUsers = async (jobId, user, inJobDelete = false) => {
    Api.delete('/jobs_users', {
      data: {
        job_id: jobId,
        user_id: user.id
      }
    })
      .then(function() {
        setDeletingUser(deletingUser - 1);
        if (!inJobDelete) {
          enqueueSnackbar('Usuario "' + user.firstname + ' ' + user.lastname + '" removido exitosamente!', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success'
          });
        }
      })
      .catch(function() {
        setDeletingUser(deletingUser - 1);
        return false;
      });
  };

  const updateJobStatus = async (jobId, statusId) => {
    var data = {};
    if (statusId === 5) {
      data = {
        job_status_id: statusId,
        comments: comments,
        extended_to: moment(selectedExtensionDate).format('YYYY-MM-DD')
      };
    } else {
      data = {
        job_status_id: statusId,
        comments: comments
      };
    }
    Api.put('/jobs/' + jobId, data)
      .then(function(response) {
        setUpdatingJob(false);
        enqueueSnackbar('Vencimiento actualizado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        setUpdatingJob(false);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const createJobHistory = async (job, user_id, statusId) => {
    Api.post('/jobs_histories', {
      job_id: job.id,
      user_id: user_id,
      job_status_id_before: job.job_status_id,
      job_status_id_after: statusId
    })
      .then(function() {
        setCreatingJobHistory(false);
        return true;
      })
      .catch(function() {
        setCreatingJobHistory(false);
        return false;
      });
  };

  const createJobFiles = async (jobId, file) => {
    const data = new FormData();
    data.append('file', file);
    Api.post('/files/upload', data)
      .then(function(response) {
        Api.post('/jobs_files', {
          job_id: jobId,
          name: file.name,
          url: response.data.fileUrl
        })
          .then(function(response) {
            setAddingFile(addingFile - 1);
            enqueueSnackbar('Archivo agregado exitosamente!', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'success'
            });
          })
          .catch(function(error) {
            setAddingFile(addingFile - 1);
            var message = 'Error de conexion, contacta a soporte';
            if (error.response) {
              message = 'Ocurrio un error, intenta nuevamente.';
            }
            enqueueSnackbar(message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'error'
            });
          });
      })
      .catch(function(error) {
        setAddingFile(addingFile - 1);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  const deleteJobFiles = async (file, inJobDelete = false) => {
    Api.delete('/jobs_files/' + file.id)
      .then(function() {
        setDeletingFile(deletingFile - 1);
        if (!inJobDelete) {
          enqueueSnackbar('Archivo "' + file.name + '" removido exitosamente!', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'success'
          });
        }
      })
      .catch(function(error) {
        setDeletingFile(deletingFile - 1);
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
        return false;
      });
  };

  const deleteJobHistory = async job_history => {
    Api.delete('/jobs_histories/' + job_history.id)
      .then(function() {
        setDeletingJobHistory(deletingJobHistory - 1);
        return true;
      })
      .catch(function() {
        setDeletingJobHistory(deletingJobHistory - 1);
        return false;
      });
  };
  const deleteJobNotification = async job_notification => {
    Api.delete('/jobs_notifications/' + job_notification.id)
      .then(function() {
        setDeletingJobNotification(deletingJobNotification - 1);
        return true;
      })
      .catch(function() {
        setDeletingJobNotification(deletingJobNotification - 1);
        return false;
      });
  };

  const handleArchiveJob = () => {
    Api.put('/jobs/' + job.id, { archived: true })
      .then(function(response) {
        handleClose(true);
        enqueueSnackbar('Vencimiento archivado exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  if (job.client !== undefined) {
    return (
      <div>
        <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Vencimiento" maxWidth="md">
          <form onSubmit={handleSaveJob}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    className={styles.marginTop}
                    multiline
                    disabled
                    fullWidth
                    label="Organismo"
                    name="organism"
                    type="text"
                    value={job.organism || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={styles.marginTop}
                    name="statuses"
                    disabled={logedUser.u_role_id === 3 || job.archived ? true : false}
                    options={statuses}
                    getOptionLabel={option => option.name}
                    defaultValue={job.status}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangeStatus(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Estado" />}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField fullWidth disabled label="Expediente" name="proceedings" type="text" value={job.proceedings || ''} variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth disabled label="Notificación" name="notification" type="text" value={job.notification || ''} variant="outlined" />
                </Grid>
              </Grid>
              <TextField
                className={styles.marginTop}
                multiline
                disabled
                fullWidth
                label="Descripción"
                name="description"
                type="text"
                value={job.description || ''}
                variant="outlined"
              />
              <TextField
                className={styles.marginTop}
                multiline
                fullWidth
                label="Comentarios"
                name="comments"
                type="text"
                defaultValue={job.comments}
                value={comments || ''}
                onChange={handleChangeComments}
                variant="outlined"
              />
              <Grid container spacing={3} className={styles.marginTop}>
                <Grid item xs={newStatusId === 5 || job.extended_to ? 3 : 4}>
                  <TextField
                    disabled
                    fullWidth
                    label="Fecha de Notificación"
                    name="notice"
                    type="text"
                    value={job.notice_on_formatted || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={newStatusId === 5 || job.extended_to ? 3 : 4}>
                  <TextField disabled fullWidth label="Fecha de Alerta" name="alert" type="text" value={job.warn_on_formatted || ''} variant="outlined" />
                </Grid>
                <Grid item xs={newStatusId === 5 || job.extended_to ? 3 : 4}>
                  <TextField
                    disabled
                    fullWidth
                    label="Fecha de Vencimiento"
                    name="expire"
                    type="text"
                    value={job.expire_on_formatted || ''}
                    variant="outlined"
                  />
                </Grid>
                {newStatusId === 5 || job.extended_to ? (
                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={momentUtils} defaultValue={job.extended_to}>
                      <KeyboardDatePicker
                        fullWidth
                        disabled={newStatusId !== 5 || job.archived}
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="DD/MM/yyyy"
                        name="extensionDate"
                        label="Fecha de Extensión"
                        value={selectedExtensionDate ? selectedExtensionDate : job.extended_to}
                        onChange={handleExtensionDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                ) : null}
              </Grid>

              <Autocomplete
                className={styles.marginTop}
                multiple
                name="users"
                disabled={logedUser.u_role_id === 3 || job.archived ? true : false}
                options={users}
                getOptionLabel={option => option.firstname + ' ' + option.lastname}
                defaultValue={job.users}
                filterSelectedOptions
                onChange={(option, value) => {
                  handleChangeUsersToNotify(value);
                }}
                renderInput={params => <TextField {...params} variant="outlined" label="Usuarios a notificar" />}
              />

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {!job.plant ? (
                    <Card className={styles.marginTop}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Cliente" name="client" type="text" value={job.client.name || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Documento" name="document" type="text" value={job.client.document || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Teléfono" name="phone" type="text" value={job.client.phone || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField disabled fullWidth label="E-mail" name="email" type="text" value={job.client.email || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Provincia" name="state" type="text" value={job.client.state || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Localidad" name="city" type="text" value={job.client.city || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Dirección" name="address" type="text" value={job.client.address || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card className={styles.marginTop}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Planta" name="client" type="text" value={job.plant.name || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField disabled fullWidth label="Contacto" name="document" type="text" value={job.plant.contact || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Teléfono" name="phone" type="text" value={job.plant.phone || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField disabled fullWidth label="E-mail" name="email" type="text" value={job.plant.email || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Provincia" name="state" type="text" value={job.plant.state || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Localidad" name="city" type="text" value={job.plant.city || ''} variant="outlined" />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField disabled fullWidth label="Dirección" name="address" type="text" value={job.plant.address || ''} variant="outlined" />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
              <Card className={styles.marginTop}>
                {logedUser.u_role_id !== 3 && !job.archived ? (
                  <CardContent>
                    <DropzoneArea
                      dropzoneClass={styles.dropzone}
                      acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/msword']}
                      onChange={handleChangeFiles}
                      showFileNames
                      dropzoneText="Arraste los archivos aquí o haga click para seleccionar"
                      showAlerts={false}
                      filesLimit={10}
                      maxFileSize={10000000}
                      onDropRejected={handleOnDocRejected}
                    />
                  </CardContent>
                ) : null}
                {jobFiles.length > 0 ? (
                  <List subheader={<ListSubheader>Archivos</ListSubheader>}>
                    {jobFiles.map((file, i) => {
                      return <JobFileItem key={i} handleConfirmDeleteJobFile={handleConfirmDeleteJobFile} logedUserRoleId={logedUser.u_role_id} file={file} />;
                    })}
                  </List>
                ) : null}
              </Card>
              <div className={styles.marginTop}>
                <MUIDataTable title={'Historial de modificaciones'} data={job.histories} columns={columns} options={options} />
              </div>
            </DialogContent>
            {logedUser.u_role_id !== 3 ? (
              <DialogActions>
                {!job.archived ? (
                  <Button onClick={handleArchive} style={{ color: '#e91e63' }}>
                    Archivar
                  </Button>
                ) : null}
                <div style={{ flex: '1 0 0' }} />
                <Button onClick={handleDelete} style={{ color: 'red' }}>
                  Eliminar
                </Button>
                {!job.archived ? (
                  <Button disabled={!modified} type="submit" color="primary">
                    Guardar
                  </Button>
                ) : null}
              </DialogActions>
            ) : null}
          </form>
          <ConfirmDialog
            handleClose={handleCloseConfirmDeleteDialog}
            handleOnConfirm={handleOnConfirmDelete}
            visibility={confirmDeleteDialogVisibility}
            title="¿Está seguro que desea eliminar este vencimiento?"
            message="La eliminación de este vencimiento es permanente"
          />
          <ConfirmDialog
            handleClose={handleCloseConfirmDismissDialog}
            handleOnConfirm={handleOnConfirmDismiss}
            visibility={confirmDismissDialogVisibility}
            title="¿Está seguro que desea salir?"
            message="Hay cambios sin guardar"
          />
          <ConfirmDialog
            handleClose={handleCloseConfirmArchiveDialog}
            handleOnConfirm={handleOnConfirmArchive}
            visibility={confirmArchiveDialogVisibility}
            title="¿Está seguro que desea archivar este vencimiento?"
            message="Podrás visualizarlo en la pantalla Historial"
          />
        </Modal>
        <Backdrop className={styles.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default JobModal;
