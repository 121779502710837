import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

moment.locale('es');

const schema = {
  name: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 3,
      maximum: 64,
      message: '^Debe contener al menos 3 caracteres'
    }
  },
  sectors: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  }
}));

const NewMatrixModal = ({ handleClose, visibility, sectors }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const [selectedSector, setSelectedSector] = useState();
  const handleChangeSector = sectorId => {
    setSelectedSector(sectorId);
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  useEffect(() => {
    if (close) {
      setClose(false);
      setLoading(false);
      handleClose(true);
    }
  }, [close]);

  const handleCloseDismiss = () => {
    handleClose();
  };

  const handleCreateMatrix = async event => {
    event.preventDefault();
    setLoading(true);
    Api.post('/matrices', {
      name: formState.values.name,
      sector_id: selectedSector
    })
      .then(function(response) {
        setClose(true);
        formState.values.name = '';
        enqueueSnackbar('Matriz creada exitosamente!', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nuevo Tipo / Matriz" maxWidth="md">
        <form onSubmit={handleCreateMatrix}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  name="sectors"
                  options={sectors}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeSector(value.id);
                    handleChange(option, 'sectors');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Sector" />}
                  error={hasError('sectors')}
                  helperText={hasError('sectors') ? formState.errors.sectors[0] : null}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  error={hasError('name')}
                  helperText={hasError('name') ? formState.errors.name[0] : null}
                  label="Nombre"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.name || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default NewMatrixModal;
