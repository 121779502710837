import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import EventIcon from '@material-ui/icons/Event';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import JWTDecoder from 'jwt-decode';

import { Profile, SidebarNav, Info } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const jwt = localStorage.getItem('access-jwt');
  var jwtUser = [];
  if (jwt !== null) {
    jwtUser = JWTDecoder(localStorage.getItem('access-jwt'));
  }

  var pages = [
    {
      title: 'Vencimientos',
      href: '/jobs',
      icon: <EventIcon />
    },
    {
      title: 'Informes',
      href: '/reports',
      icon: <DescriptionIcon />
    },
    {
      title: 'Certificación de Equipos',
      href: '/equipments_certificates',
      icon: <VerifiedUserIcon />
    },
    {
      title: 'Cronogramas',
      href: '/schedules',
      icon: <DateRangeIcon />
    }
  ];
  var adminPages = [
    {
      title: 'Panel',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Archivo de Vencimientos',
      href: '/jobs-history',
      icon: <AllInboxIcon />
    },
    {
      title: 'Archivo de Informes',
      href: '/reports-history',
      icon: <AllInboxIcon />
    },
    {
      title: 'Clientes',
      href: '/clients',
      icon: <BusinessCenterIcon />
    },
    {
      title: 'Usuarios',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: 'Configuración',
      href: '/configurations',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        {jwtUser.u_role_id !== 3 ? (<div><Divider className={classes.divider} /><SidebarNav className={classes.nav} pages={adminPages} /></div>) : null}
        <Info />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
