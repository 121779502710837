import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';

import Api from '../../../Api';
import Modal from '../../../components/Modal/Modal';

const schema = {
  firstname: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 2,
      maximum: 64,
      message: '^Debe contener al menos 2 caracteres'
    }
  },
  lastname: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 2,
      maximum: 64,
      message: '^Debe contener al menos 2 caracteres'
    }
  },
  email: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    email: { message: '^Invalido' },
    length: {
      maximum: 64,
      message: '^No debe contener mas de 64 caracteres'
    }
  },
  password: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    length: {
      minimum: 8,
      maximum: 128,
      message: '^Debe contener al menos 8 caracteres'
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' },
    equality: {
      attribute: 'password',
      message: '^No coincide con la contraseña ingresada'
    }
  },
  role: {
    presence: { allowEmpty: false, message: '^Campo obligatorio' }
  }
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 200px) !important'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const NewUserModal = ({ handleClose, onSuccess, visibility, roles, clients }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    event.persist();

    if (event.target.name) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: event.target.value
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const [selectedClientHasPlant, setSelectedClientHasPlant] = useState(false);
  const [selectedRol, setSelectedRol] = useState();
  const [plants, setPlants] = React.useState([]);
  const handleChangeRol = rolId => {
    if (rolId === 3) {
      schema['client'] = {
        presence: { allowEmpty: false, message: '^Campo obligatorio' }
      };
    } else {
      setSelectedClient();
      setSelectedClientHasPlant(false);
      setSelectedPlant();
      delete schema.client;
    }

    setSelectedRol(rolId);
    formState.values.client = '';
  };

  const [selectedClient, setSelectedClient] = useState();
  const handleChangeClient = clientId => {
    setSelectedClient(clientId);
    setSelectedPlant();
    clients.forEach(client => {
      if (client.id === clientId) {
        if (client.plants.length > 0) {
          setPlants(client.plants);
          setSelectedClientHasPlant(true);
        } else {
          setSelectedClientHasPlant(false);
        }
      }
    });
  };

  const [selectedPlant, setSelectedPlant] = useState();
  const handleChangePlant = plantId => {
    setSelectedPlant(plantId);
  };

  const handleCloseDismiss = () => {
    setSelectedClientHasPlant(false);
    handleClose();
  };

  const handleCreateUser = async event => {
    event.preventDefault();
    Api.post('/users', {
      firstname: formState.values.firstname,
      lastname: formState.values.lastname,
      email: formState.values.email,
      role_id: selectedRol,
      client_id: selectedClient,
      plant_id: selectedPlant,
      password: formState.values.password,
      confirmPath: '/confirm-email'
    })
      .then(function(response) {
        formState.values.firstname = '';
        formState.values.lastname = '';
        formState.values.email = '';
        formState.values.password = '';
        formState.values.confirmPassword = '';
        handleCloseDismiss();
        onSuccess();
        enqueueSnackbar('¡Usuario creado con éxito! Enviamos un email para su activación', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'success'
        });
      })
      .catch(function(error) {
        var message = 'Error de conexion, contacta a soporte';
        if (error.response) {
          message = 'Ocurrio un error, intenta nuevamente.';
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          variant: 'error'
        });
      });
  };

  return (
    <div>
      <Modal handleClose={handleCloseDismiss} visibility={visibility} title="Nuevo Usuario">
        <form onSubmit={handleCreateUser}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  error={hasError('firstname')}
                  fullWidth
                  helperText={hasError('firstname') ? formState.errors.firstname[0] : null}
                  label="Nombre"
                  name="firstname"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.firstname || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={hasError('lastname')}
                  fullWidth
                  helperText={hasError('lastname') ? formState.errors.lastname[0] : null}
                  label="Apellido"
                  name="lastname"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.lastname || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  label="E-Mail"
                  name="email"
                  type="text"
                  onChange={handleChange}
                  value={formState.values.email || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={selectedRol === 3 ? (selectedClientHasPlant ? 4 : 6) : 12}>
                <Autocomplete
                  name="role"
                  options={roles}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  disableClearable
                  onChange={(option, value) => {
                    handleChangeRol(value.id);
                    handleChange(option, 'role');
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" label="Rol" />}
                />
              </Grid>
              {selectedRol === 3 ? (
                <Grid item xs={selectedClientHasPlant ? 4 : 6}>
                  <Autocomplete
                    name="client"
                    options={clients}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangeClient(value.id);
                      handleChange(option, 'client');
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Cliente" />}
                  />
                </Grid>
              ) : null}
              {selectedClientHasPlant ? (
                <Grid item xs={4}>
                  <Autocomplete
                    name="plants"
                    key={formState.values.client}
                    options={plants}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    disableClearable
                    onChange={(option, value) => {
                      handleChangePlant(value.id);
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Planta" />}
                    error={hasError('plants')}
                    helperText={hasError('plants') ? formState.errors.plants[0] : null}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password[0] : null}
                  label="Contraseña"
                  name="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={hasError('confirmPassword')}
                  fullWidth
                  helperText={hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null}
                  label="Confirmacion de Contraseña"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.confirmPassword || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button disabled={!formState.isValid} type="submit" color="primary">
              Crear
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </div>
  );
};

export default NewUserModal;
